.sidebar {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px;
  max-width: 82px;
  height: 100vh;
  position: fixed;
  width: 82px;
  z-index: 999;

  &-menu {
    list-style: none;

    li {
      margin-bottom: 0;

      a {
        color: #fff;
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    .active {
      path {
        fill: #000000;
      }
    }
  }
}

.sidebarResponsive {
  background: #111;
  display: none;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 62px;

  &-menu {
    list-style: none;

    li {
      margin-bottom: 40px;

      a {
        color: #fff;
        opacity: 0.5;
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    .active {
      opacity: 1;
    }
  }

  .navbar-iconWrapper {
    .navbar-iconMenu div {
      background: #fff;
    }
  }

  .navbar-iconWrapper {
    height: 22px;
    width: 22px;
  }
}

.backofficeContainer {
  width: calc(100% - 82px);
  margin-left: auto;
  z-index: 998;

  &__optionTotal {
    align-items: center;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    width: 100%;
  }

  &-header {
    padding: 40px;
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: space-between;

    &__about {
      align-items: baseline;
      display: flex;
    }

    &__title {
      font-family: $font-semibold;
      font-size: 18px;
      line-height: 23px;
      color: #000000;
    }

    &__titleAlt {
      font-size: 14px;
      line-height: 23px;
      color: $primary-color;
      margin-left: 10px;
    }
  }

  &-headerMin {
    padding: 0 40px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 82px;

    &__item {
      position: relative;
      z-index: 9999;
    }

    .button {
      position: relative;
      z-index: 9999;
    }

    select {
      margin-right: 20px;
    }

    &-tab {
      color: #111;
      margin-right: 20px;
    }

    .active {
      color: $primary-color;
    }
  }
}

.backoffice {
  display: flex;
}

.backofficeTable {
  padding: 0 40px;

  hr {
    border-color: #E8E8EE;
    margin-bottom: 20px;
    max-width: none;
  }

  th {
    border-color: #E8E8EE;
    color: #66666a;
    padding-bottom: 20px;
    padding-top: 0;
    font-family: $font-semibold !important;
  }

  td {
    border-color: #E8E8EE;
    color: #111;
  }

  table {
    width: 100%;
  }

  .table-link {
    color: $primary-color;
  }

  .dotYes {
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    text-align: center;
    color: $primary-color;
  }

  .dotNo {
    display: block;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    text-align: center;
    color: #acacb5;
  }
}

.approvedBtns {
  display: flex;
  justify-content: space-around;

  a {
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 36px;
    width: 36px;
    text-align: center;
    line-height: 36px;
    font-family: "Inter", sans-serif;

    &:hover {
      background: $primary-color;
      color: #fff;

      path {
        stroke: #fff;
      }
    }
  }
}

.table-link {
  text-decoration: underline;
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.modalTitle {
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  margin-bottom: 20px;
}

.modalInput {
  margin-bottom: 20px;

  select {
    width: 100%;
  }

  &-subcategory {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
  }

  &-subcategoryName {
    display: flex;

    svg {
      margin-right: 10px;
    }
  }
}

.MuiPaper {
  &-root {
    box-shadow: none !important;
    white-space: nowrap;
    // position: relative !important;
    margin-top: -67px !important;
    margin-bottom: 60px !important;
    // background: transparent !important;
  }
}

.MuiTableCell-root {
  font-family: $font-regular !important;
}

.modalRow {
  margin-bottom: 0;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  display: none;
}

.MuiInput-underline {
  border: 1px solid #e8e8ee !important;
  border-radius: 6px;
  padding-left: 15px;
  transition: all .25s ease;
}

.Mui-focused {
  border: 1px solid #CECEDB !important;
}

.MTableToolbar-searchField-10 {

  position: absolute;
  right: 0;
  top: -110px;

  &:focus {
    border-color: #000;
  }

  input {
    border: none !important;
  }
}

@media screen and (max-device-width: 768px) {
  .backoffice {
    flex-direction: column;
  }

  .backofficeContainer {
    width: 100%;

    &-header {
      align-items: flex-start;
      flex-direction: column;
      padding: 0;
      margin: 40px;
    }
  }

  .sidebar {
    display: none;
  }

  .sidebarResponsive {
    display: flex;
  }
}