.docs-header {
    text-align: center;
    margin-bottom: 80px;

    &__about {
        font-size: 20px;
        line-height: 28px;
        /* identical to box height, or 140% */
        text-align: center;
        color: #66666A;
        margin-bottom: 40px;
    }

    .title {
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
}

.docs-sidebar {
    border-left: 1px solid #eee;
    padding: 20px;
    // display: flex;
    // align-items: flex-start;
    // overflow: visible;
    // height: 360px;


    .active {
        color: $primary-color;
        font-family: $font-semibold;
    }

    &__list {
        // position: -webkit-sticky;
        // position: sticky;

        // top: 0;
        list-style: none;

        li:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
}

.docs-info {
    border-bottom: 1px solid #eee;
    display: flex;
    padding-bottom: 40px;
    margin-bottom: 40px;

    &__title {
        max-width: 260px;
    }

    &__text {
        max-width: 550px;
        margin-left: auto;
        padding-left: 1rem;
        font-size: 14px;
        line-height: 20px;
        color: #66666A;
    }
}

@media (max-width: 768px) {
    .docs-header {
        margin-top: 100px;
    }

    .docs-info {
        flex-direction: column;

        &__title {
            margin-bottom: 15px;
        }

        &__text {
            padding-left: 0;
        }
    }
}