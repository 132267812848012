.home {
   &-cover {
      padding: 182px 0 100px 0;      

      &__title {
         font-family: $font-semibold;
         font-size: 2.625rem;
         line-height: 100%;
         color: #111;
         margin-right: 27%;
      }

      &__subtitle {
         color: #111;
         font-size: 1.25rem;
         margin-top: 40px;
         margin-right: 20%;
      }

      &__profiles {
         display: flex;
         margin-top: 40px;
      }

      &__profilesResult {
         display: flex;
         margin: 20px 0;
      }
   }

   &-galleryEmpty {
      z-index: 990 !important;
   }

   &-galleryProfile {
      margin-top: -41px;
   }

   &-gallery {
      z-index: 9997;

      &__margin {
         margin-top: 82px;
      }

      &__marginPlus {
         margin-top: 132px;
      }

      &__header {
         align-items: center;
         display: flex;
         justify-content: center;
         margin-bottom: 50px;

         select {
            width: 160px;
            margin-bottom: 0;
         }
      }

      &__filtersProfile {
         justify-content: center !important;
      }

      &__filters {
         border-top: 1px solid #e8e8ee;
         border-bottom: 1px solid #e8e8ee;
         padding: 15px 0;
         margin-bottom: 40px;
         display: flex;
         align-items: center;
         justify-content: space-between;

         &-corner {
            display: block;
            width: 28px;
         }

         &-list {
            align-items: center;
            display: flex;
            justify-content: center;
            list-style: none;
         }

         li {
            color: #66666a;
            font-family: $font-regular;
            margin-bottom: 0;
            padding: 0 8px;
            text-transform: capitalize;

            a:hover {
               color: #888888;
            }
         }
      }

      &__filtersContainer {
         display: flex;
         align-items: center;
         justify-content: center;

         button {
            margin-left: 20px;
         }
      }

      &__headerResults {
         align-items: center;
         display: flex;
         justify-content: space-between;
         padding: 40px 0;

         select {
            width: 160px;
            margin-bottom: 0;
         }
      }

      &__headerTags {
         align-items: center;
         display: flex;
         justify-content: space-between;
         line-break: anywhere;
         margin-bottom: 45px;

         a {
            display: inline-block;
         }
      }

      &__headerSelects {
         display: flex;
         align-items: center;
         justify-content: flex-end;

         .inputSelect {
            margin-left: 20px;
         }
      }
   }
}

.productPrice {
   :after {
      background-color: rgba(255, 255, 255, 0.5);
      content: "";
      display: inline-block;
      height: 1em;
      width: 1.2em;
      position: relative;
      top: 0.25em;
      right: 1.2em;
   }

   &-discount {
      text-decoration: line-through;
      opacity: .6;
      margin-right: 10px;
   }
}

.profile-card {
   align-items: center;
   cursor: pointer;
   display: flex;
   flex-direction: column;
   width: 98px;

   &__icon {
      height: 60px;
      width: 60px;
      border-radius: 60px;
      border: none;
      margin-bottom: 10px;
      transition: all .25s ease;
   }

   &__name {
      font-family: $font-semibold;
      font-size: 12px;
      width: 93px;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      text-overflow: ellipsis;
      text-transform: capitalize;
   }

   &__action {
      justify-content: center;
   }

   &:hover {
      .profile-card__icon {
         transform: translateY(-5px);
      }
   }
}

.profile-cardHome {
   align-items: center;
   cursor: pointer;
   display: flex;
   flex-direction: column;
   margin-right: 20px;

   &__icon {
      height: 60px;
      width: 60px;
      border-radius: 60px;
      border: none;
      margin-bottom: 10px;
      transition: .3s ease;

      &:hover {
         transform: translateY(-5px);
      }
   }

   &__iconPro::after {
      content: '';
      background: url('../images/pro_badge.svg');
      background-position: center center;
      background-size: cover;
      height: 18px;
      width: 18px;
      position: absolute;
      margin-left: -17px;
      margin-top: 42px;
   }



   &__name {
      width: 60px;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      text-overflow: ellipsis;
   }

   &__action {
      justify-content: center;
   }
}

.profile-card__large {
   align-items: center;
   display: flex;
   width: 100%;
   justify-content: space-between;
   margin-bottom: 40px;

   &:hover {
      .profile-card__large-name {
         color: $primary-color;
         transform: translateX(5px);
      }
   }

   &__icon {
      height: 60px;
      width: 60px;
      border-radius: 60px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
   }

   &-name {
      font-family: $font-semibold;
      font-size: 16px;
      line-height: 20px;
      transition: all .3s ease;
   }

   &-block {
      align-items: center;
      display: flex;
   }

   &-blockAlt {
      align-items: center;
   }

   .profile-card__icon {
      margin-right: 20px;
   }
}

.toggle-btn {
   cursor: pointer;
   width: 32px;
   height: 32px;
   background: #fff;
   border-radius: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   transition: all 0.25s ease;

   &:hover {
      background: #000;

      circle {
         fill: #fff;
      }
   }
}

.homeGrid {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr;
   grid-column-gap: 40px;

   &-item {
      max-width: 260px;
      width: 100%;
      margin: auto;
      margin-bottom: 50px;
      position: relative;
      padding-top: 5px;

      &__dropdown {
         display: flex;
         justify-content: space-between;
      }

      &__cover {
         opacity: 1;
         display: block;
         width: 100%;
         height: auto;
         transition: 0.5s ease;
         backface-visibility: hidden;
      }

      &__coverAd {
         border-radius: 12px;

         div {
            border-radius: 12px;
         }
      }

      &__coverOverlay {
         background: rgba(0, 0, 0, 0.15);
         transition: 0.5s ease;
         opacity: 0;
         position: absolute;
         top: 0;
         text-align: center;
         width: 100%;
         height: 100%;
         display: flex;
         align-items: flex-end;
         justify-content: flex-end;
         padding: 20px;
         z-index: 10;

         &-link {
            position: absolute;
            right: 20px;
            bottom: 20px;
            z-index: 10;

            circle {
               transition: all .3s ease;
            }

            #bagBg,
            #zipper {
               transition: all .3s ease;
            }

            &:hover {
               circle {
                  fill-opacity: .9;
               }

               #bagBg {
                  fill: #fff;
               }

               #zipper {
                  stroke: #000;
               }

               #handle {}
            }

         }

         &-frame {
            z-index: 9;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
         }

         &:hover {
            .homeGrid-item__coverOverlay-link {
               display: block;
            }
         }
      }

      &__top {
         position: relative;
      }

      &__topAd {
         position: relative;
      }

      &__info {
         display: flex;
         margin-top: 20px;
         align-items: center;
         // p:nth-child(2) {
         //    color: #999;
         // }
      }

      &__icon {
         cursor: pointer;
         border-radius: 30px;
         height: 30px;
         width: 30px;
         max-width: 30px;
         margin-right: 16px;
      }

      &__iconPro::after {
         content: '';
         background: url('../images/pro_badge.svg');
         background-position: center center;
         background-size: cover;
         height: 14px;
         width: 14px;
         position: absolute;
         margin-left: -27px;
         margin-top: 19px;
      }

      &__name {
         display: block;
         font-family: $font-semibold;
         text-transform: capitalize;
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
         width: 200px;
      }

      &__nameOnly {
         line-height: 42px;
      }
   }

   &-item-empty {
      background: #FFFFFF;
      border: 1px dashed rgba(102, 102, 106, 0.25);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90%;
      min-height: 411px;
      width: 100%;
      margin: auto;
      margin-bottom: 50px;
      position: relative;
      transition: .3s ease;

      svg {
         display: none;
      }

      &:hover {
         border: 1px dashed #111;

         svg {
            display: block;
         }
      }
   }

   &-item__top:hover {

      .homeGrid-item__cover {
         transform: translateY(-5px);
      }

      .homeGrid-item__coverOverlay {
         transform: translateY(-5px);
         opacity: 1;
      }
   }
}

.homeGrid2 {
   display: grid;
   grid-template-columns: 1fr 1fr;
   grid-column-gap: 40px;

   &-item {
      max-width: 560px;
      margin: auto;
      margin-bottom: 50px;
      position: relative;

      img {
         width: 100%;
      }

      &__cover {
         opacity: 1;
         display: block;
         width: 100%;
         height: auto;
         transition: 0.5s ease;
         backface-visibility: hidden;
      }

      &__coverOverlay {
         background: rgba(0, 0, 0, 0.15);
         transition: 0.5s ease;
         opacity: 0;
         position: absolute;
         top: 0;
         text-align: center;
         width: 100%;
         height: 100%;
         display: flex;
         align-items: flex-end;
         justify-content: flex-end;
         padding: 20px;
      }

      &__top {
         position: relative;
      }

      &__info {
         display: flex;
         justify-content: space-between;
         margin-top: 20px;

         p:nth-child(2) {
            color: #999;
         }
      }

      &__icon {
         max-width: 30px;
         margin-right: 16px;
      }
   }

   &-item:hover {
      .homeGrid-item__cover {
         box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      }

      .homeGrid-item__coverOverlay {
         opacity: 1;
      }
   }
}

.category-cover {
   text-align: center;
}

.about-container {
   padding: 7.5rem 0;

   h5.accordion {
      color: #1d2647;
      cursor: pointer;
      width: 100%;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
      margin-bottom: 10px;
      font-size: 18px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
   }

   .accordionActive {
      color: $primary-color;
   }

   /* Add a background color to the accordion if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
   h5.accordion.active,
   h5.accordion:focus,
   h5.accordion:active,
   h5.accordion:visited,
   h5.accordion:hover {
      color: $primary-color;
   }

   .panel {
      color: #000;
      padding: 0 40px 40px;
      text-align: left;
   }

   /* Style the element that is used for the panel class */

   div.panel {
      padding: 18px;
      background-color: white;
      max-height: 0;
      overflow: hidden;
      transition: 0.4s ease-in-out;
      opacity: 0;
      margin-bottom: 10px;
   }

   div.panel.show {
      opacity: 1;
      max-height: 500px;
      /* Whatever you like, as long as its more than the height of the content (on all screen sizes) */
   }

   &__cover {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 50px;

      img {
         margin-right: 40px;
         margin-top: 50px;
      }
   }

   &__infoBox {
      border: 1px solid #e8e8ee;
      border-radius: 6px;
      margin-left: 40px;
      margin-top: 50px;
      max-width: 360px;
      width: 100%;
   }

   &__infoItemDiv {
      border-bottom: 1px solid #e8e8ee;

      &:nth-last-child(1) {
         border-bottom: none;
      }

      p {
         font-size: 14px;
         line-height: 18px;
         color: #66666a;
         margin-top: 20px;
      }
   }

   &__infoItem {
      padding: 40px;
      padding-bottom: 0;

      &:nth-last-child(1) {
         padding-bottom: 40px;
      }

      button {
         color: #111;
         font-family: $font-semibold;
         font-size: 1rem;
         background: none;
         padding: 0;
         height: auto;
         line-height: normal;

         &:focus {
            color: $primary-color;
         }

         &:hover {
            color: $primary-color;
         }

         &:active {
            color: $primary-color;
         }

         &:target {
            color: $primary-color;
         }

         &:visited {
            color: $primary-color;
         }
      }

   }
}

.searchError-container {
   height: 400px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.searchError-message {
   color: #999;
   font-family: $font-semibold;
   margin-top: 6rem;
   margin-bottom: 2rem;
   font-size: 18px;
   text-align: center;

   span {
      color: #000;
   }
}

.page404-title {
   margin-bottom: 20px;
   margin-top: 5rem;
   text-align: left;
   max-width: 480px;
   font-family: $font-semibold;
   color: #111;
}

.page404-cover {
   position: absolute;
   left: 50%;
   transform: translate(-50%, 40%);
   top: 50%;
}

.page404-message {
   font-size: 16px;
   text-align: left;
   max-width: 480px;
   font-family: $font-regular;
   color: #444;
}

.page404-link {
   color: #999;
   margin-top: 1.5rem;
   font-size: 14px;
   text-align: left;
   max-width: 480px;
}

.rowLightbox {
   display: flex;
   align-items: center;
}


.modalItem {
   background: #fff;

   &-button {
      align-items: center;
      position: absolute;
      bottom: 40px;
      right: 40px;

      svg {
         margin-left: 10px;
      }
   }

   &-cover {
      backdrop-filter: blur(30px);
      background-repeat: no-repeat !important;
      background-size: cover !important;
      height: 100%;
      height: 100vh;
      display: flex;
      position: relative;
      &__sliderContainer {
         width: 80%;
         margin: auto;
      }

      img {
         width: 100%;
         width: 80%;
         max-width: 520px;
         margin: auto;
         display: block;
         z-index: 1;
      }

      .slick-slider {
         height: auto;
         width: 100%;
      }

      .slick-prev {
         left: -45px;
         width: 42px;
         height: 42px;
      }

      .slick-next {
         right: -45px;
         width: 42px;
         height: 42px;
      }
   }

   &-coverBlur {
      width: 100%;
      position: absolute;
      height: 100%;
      backdrop-filter: blur(10px);
   }

   &-info {
      padding: 40px;

      &__nameRow {
         display: flex;
         justify-content: space-between;

         .modalItem-info__actions {
            display: none;
         }
      }



      hr {
         margin-bottom: 20px;
      }

      &__about {
         white-space: pre-line;
         word-break: break-word;
      }

      &__aboutShipping {
         margin-top: 20px;

         .itemTagAlt {
            display: inline-flex;
            margin-left: 0;
         }
      }

      &__logo {
         margin-bottom: 20px;
         display: flex;
         align-items: center;
         justify-content: space-between;

         img {
            clip-path: circle(60px at center);
            cursor: pointer;
            height: 60px;
            width: 60px;
            border-radius: 60px;
         }
      }

      &__logoPro::after {
         content: '';
         background: url('../images/pro_badge.svg');
         background-position: center center;
         background-size: cover;
         height: 24px;
         width: 24px;
         position: absolute;
         margin-left: -20px;
         margin-top: 40px;
      }

      &__logoProMini::after {
         content: '';
         background: url('../images/pro_badge.svg');
         background-position: center center;
         background-size: cover;
         height: 20px;
         width: 20px;
         position: absolute;
         margin-left: -15px;
         margin-top: 27px;
      }

      &__actions {
         &-btn {
            cursor: pointer;
            margin-left: 10px;

            rect,
            path {
               transition: all .3s ease;
            }

            &:hover {

               rect {
                  fill: #111;
               }

               path {
                  stroke: #fff;
               }
            }
         }
      }

      h5 {
         color: #111;
         font-family: $font-semibold;
         font-size: 20px;
         line-height: 25px;
         margin-bottom: 10px;
      }

      &__price {
         font-size: 14px;
         line-height: 18px;
         color: #66666a;
         margin-bottom: 20px;
      }

      &__tags {
         font-size: 14px;
         line-height: 18px;
         color: #66666a;
         margin-top: 20px;

         a:nth-child(1) {
            margin-left: 0;
         }
      }

      &__button {
         margin-left: 0;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-top: 20px;
         width: 100%;

         svg {
            margin-left: 10px;
         }

         circle {
            transition: all .3s ease;
         }

         #bagBg,
         #zipper {
            transition: all .3s ease;
         }

         &:hover {
            circle {
               fill-opacity: 0;
            }

            #bagBg {
               fill: #fff;
            }

            #zipper {
               stroke: #333;
            }

            #handle {}
         }
      }

      &__buttonMobile {
         display: flex;
         align-items: center;
         justify-content: center;
         margin-top: 40px;
         width: 100%;

         svg {
            margin-left: 10px;
         }
      }
   }

   &-respNav {
      .okkiLogoMobile {
         top: -2px;
      }
   }
}

.modalItem-info__buttonMobile {
   display: none;
}

.okkiLogoModal {
   display: none;
   position: absolute;
   left: 20px;
   top: 20px;

   img {
      clip-path: circle(42px at center);
      height: 42px;
      width: 42px;
      border-radius: 42px;
   }
}

.moreOptionsToggle {
   cursor: pointer;
}

.noDisplay {
   display: none;
}

.search-header {
   padding-top: 62px;

   &__breadcrumb {
      display: flex;
   }



   &__breadcrumbAlt {
      display: flex;
      align-items: center;

      svg {
         margin-right: 20px;
      }
   }

   &__breadcrumbAlt2 {
      display: flex;
      justify-content: space-between;
   }

   &__tags {
      padding: 10px 0;
      text-align: center;
      line-height: 300%;
      .itemTag {
         margin-bottom: 0;
         display:inline-block;
         line-height: 145%;
      }
      .itemTagButton {
         white-space: nowrap;
      }
   }

   &__tags-extra {
      display: none;
      margin-top: 25px;
   }

   &__tags-extraShow {
      display: block;
   }

   &__profiles {
      // justify-content: space-around;   
   }
}

.safe-quote {
   display: flex;
   justify-content: space-between;
   margin-bottom: 20px;

   p {
      font-size: 12px;
      line-height: 15px;
      color: #66666A;
      max-width: 210px;
   }

   span {
      color: #111;
      font-family: $font-semibold;
      font-size: 14px;
   }
}

.safe-quotePrice {
   display: flex;
   justify-content: space-between;
   margin-bottom: 20px;
   padding: 10px 0;

   p {
      font-size: 12px;
      line-height: 15px;
      color: #66666A;
      max-width: 210px;
   }

   span {
      color: #111;
      font-family: $font-semibold;
      font-size: 14px;
   }
}

.pro-container {
   padding-bottom: 4rem;

   .row {
      text-align: center;
   }

   &__checkout {
      .okkiLogo {
         display: block;
      }
   }

   &__checkoutColumn {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
   }

   &__checkoutAbout {
      max-width: 340px;
   }

   &__checkoutForm {
      background: #FFFFFF;
      box-shadow: -8px 0px 19px rgba(8, 16, 39, 0.04);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;

      &-box {
         max-width: 340px;
         width: 100%;

         .modalItem-info__button {
            margin-top: 0;
            margin-bottom: 20px;
         }
      }

      &-label {
         font-size: 14px;
      }

      &-cardInfo {
         display: flex;
         justify-content: space-between;
      }
   }

   &__checkoutClose {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 20px;
      transition: all .3s ease;

      &:hover {
         opacity: .3;
      }
   }

   &__checkoutPlans {
      display: flex;

      div {
         width: 150px;
      }

      div:nth-child(1) {
         margin-right: 40px;
      }
   }

   &__checkoutMessage {
      font-size: 14px;
      line-height: 18px;
      padding: 20px;
      color: #000000;
      background: rgba(255, 161, 20, 0.25);
      border-radius: 6px;

   }

   &__hero {
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
      max-width: 560px;
      position: relative;
      width: 100%;

      &-title {
         font-family: $font-semibold;
         font-size: 30px;
         line-height: 38px;
         text-align: center;
      }

      &-subtitle {
         font-family: $font-semibold;
         font-weight: normal;
         font-size: 14px;
         line-height: 18px;
         margin-bottom: 20px;
         text-align: center;
      }

      &-cover {
         margin-top: 40px;
         max-width: 420px;
         width: 100%;
      }
   }

   &__tableMobile {
      padding: 20px;
      text-align: left;

      &-item-row {
         display: flex;
         justify-content: space-between;
         width: 100%;
      }

      &-item {
         border-bottom: 1px solid #EEEEEE;
         margin-bottom: 20px;
         padding: 20px;
         padding-top: 0;
      }

      .pro-container__table-feature {
         font-size: 16px;
         margin-bottom: 20px;
      }
   }

   &__table {
      margin: 0 auto;
      margin-top: 160px;
      max-width: 860px;
      position: relative;
      width: 100%;

      &-toggle {
         align-items: center;
         display: flex;
         justify-content: center;
         margin-top: 20px;

         /* The switch - the box around the slider */
         .switch {
            position: relative;
            display: inline-block;
            width: 24px;
            height: 15px;
            margin: 0px 10px;
         }

         /* Hide default HTML checkbox */
         .switch input {
            opacity: 0;
            width: 0;
            height: 0;
         }

         /* The slider */
         .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
         }

         .slider:before {
            position: absolute;
            content: "";
            height: 9px;
            width: 9px;
            left: 2px;
            bottom: 3px;
            background-color: white;
            transition: .4s;
         }

         input:checked+.slider {
            background-color: $primary-color;
         }

         input:focus+.slider {
            box-shadow: 0 0 1px #2196F3;
         }

         input:checked+.slider:before {
            -webkit-transform: translateX(10px);
            -ms-transform: translateX(10px);
            transform: translateX(10px);
         }

         /* Rounded sliders */
         .slider.round {
            border-radius: 34px;
         }

         .slider.round:before {
            border-radius: 50%;
         }

      }

      th {
         padding-left: 0;
      }

      td {
         padding: 40px 0;

         .button {
            margin: auto;
         }
      }

      svg {
         path:nth-child(1) {
            transition: all .25s ease;
         }

         &:hover {
            path:nth-child(1) {
               fill: #111;
               fill-opacity: 1;
            }
         }
      }

      &-feature {
         font-family: $font-semibold;
         color: #111;
      }

      &-feature-center {
         text-align: center;
      }

      &-featureAbout {
         color: #66666A;
         margin: 20px 0;
      }

      &-featureLink {
         display: block;
         font-family: $font-semibold;
         margin-bottom: 40px;

         span {
            font-family: "Inter";
         }
      }

      &-free {
         background: #FFFFFF;
         border: 2px solid #E8E8EE;
         box-sizing: border-box;
         border-radius: 12px;
         margin-bottom: 45px;
         padding: 20px;

         span {
            display: block;
            font-family: $font-semibold;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 5px;
            color: #111;
         }
      }

      &-pro {
         background: $primary-color;
         border: 2px solid $primary-color;
         box-sizing: border-box;
         border-radius: 12px;
         margin-bottom: 20px;
         padding: 20px;

         span {
            display: block;
            font-family: $font-semibold;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 5px;
            color: #fff;
         }
      }

      &-freeCheckout {
         background: #FFFFFF;
         border: 2px solid #E8E8EE;
         box-sizing: border-box;
         border-radius: 12px;
         cursor: pointer;
         margin-bottom: 20px;
         padding: 20px;

         span {
            display: block;
            font-family: $font-semibold;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 5px;
            color: #111;
         }
      }


      &-freeCheckout-checked {
         background: $primary-color;
         border: 2px solid $primary-color;
         box-sizing: border-box;
         border-radius: 12px;
         margin-bottom: 20px;
         padding: 20px;

         span {
            display: block;
            font-family: $font-semibold;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 5px;
            color: #fff;
         }

         p {
            color: #fff;
         }
      }

   }

   .itemBadge {
      border: 1px solid #111;
      border-radius: 6px;
      display: inline-block;
      padding: 2px 5px;
      transition: .3s ease;

      &:hover {
         background: #111;
         color: #fff !important;
      }
   }

   &__check {
      margin-left: auto;
      display: block;
      margin-bottom: 8px;
   }

   &__actions {
      margin-top: 40px;
      display: flex;
      justify-content: center;

      .button {
         margin: 0 10px;
      }
   }

   &__cover {
      margin-top: -100px;
   }

   &__cards {
      display: flex;
      justify-content: center;
      margin: 40px 0 80px;
   }

   &__cardsItem {
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      box-sizing: border-box;
      border-radius: 12px;
      display: inline-table;
      margin: 0 20px;
      padding: 40px;
      text-align: left;
      width: 90%;
      max-width: 360px;

      &-badgeFree {
         font-family: $font-semibold;
         font-size: 14px;
         line-height: 18px;
      }

      &-price {
         font-family: $font-semibold;
         font-size: 34px;
         line-height: 33px;
         margin: 20px 0;

         span {
            color: #888888, 100%;
         }
      }

      &-priceMonth {
         color: #888888;
         margin-left: 5px;
      }

      &-priceRow {
         display: flex;
         align-items: baseline;
      }
   }

   &__cardsItemPro {
      border: 2px solid #000000;
   }

   &__outro {
      margin: auto;
      max-width: 360px;
      width: 100%;

      &-title {
         font-family: $font-semibold;
         font-size: 20px;
         line-height: 25px;
         text-align: center;
      }

      .buttonPro {
         margin: 40px auto 0;
      }
   }

   &__faq {
      margin-bottom: 80px;

      h3 {
         margin-bottom: 20px;
      }

      &-item {
         display: block;
         margin: auto;
         margin-top: 40px;
         max-width: 560px;
         text-align: left;
      }

      &-title {
         font-family: $font-semibold;
         margin-bottom: 20px;
      }

      &-columns {
         display: flex;
         justify-content: space-between;
         margin-top: 20px;
      }
   }
}

.priceHidden {
   height: 21px;
}

.priceRow {
   display: flex;
   sup {
      font-size: x-small;
      vertical-align: text-top;
   }
}

.termsContainer {
   max-width: 560px;
   margin: auto;

   hr {
      margin-bottom: 20px;
   }

   &-title {}

   &-text {
      font-size: 14px;
      line-height: 20px;
      margin: auto;
      margin-bottom: 20px;
      max-width: 560px;
      color: #66666A;
   }

   span {
      color: #000;
      font-family: $font-semibold;
   }
}

.text-center {
   text-align: center;
}

.shareModal {
   display: flex;
   flex-direction: column;
   justify-content: space-between;

   a {
      margin-bottom: 0;
   }

   &-button {
      align-items: center;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      box-sizing: border-box;
      border-radius: 6px;
      color: #66666a;
      display: flex;
      font-family: $font-regular;
      justify-content: space-between;
      padding: 10px 14px;
      width: 100%;
      margin-bottom: 10px;
   }

   .facebook:hover {
      background: #3B74EB;
      border: 1px solid #3B74EB;
      color: #fff;

      path {
         stroke: #fff;
      }
   }

   .twitter:hover {
      background: #00ACEE;
      border: 1px solid #00ACEE;
      color: #fff;

      path {
         stroke: #fff;
      }
   }

   .whatsapp:hover {
      background: #25D366;
      border: 1px solid #25D366;

      path {
         fill: #fff;
      }
   }

   .link:hover {
      background: #111;
      border: 1px solid #111;
      color: #fff;

      path {
         stroke: #fff;
      }
   }
}

.flagModal {
   position: relative;

   &-close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
   }

   &-row {
      padding: 12px 0;
      margin-bottom: 0 !important;
   }

   .titleMinSub {
      margin-bottom: 20px;
   }

   .checkmark {
      top: 12px;
      right: 0;
      left: unset;
   }

   &-button {
      display: flex;
      align-items: center;
      margin-top: 10px;
      width: 100%;
      justify-content: center;
      position: relative;
      padding-right: 40px;

      &:hover {
         svg {
            right: 110px;
         }
      }

      svg {
         transition: all .3s ease;
         position: absolute;
         right: 116px;
         margin-left: 5px;
      }
   }

   .buttonPublish {
      align-items: center;
      position: relative;
      padding-right: 40px;

      &:hover {
         svg {
            right: 5px;
         }
      }

      svg {
         transition: all .3s ease;
         position: absolute;
         right: 10px;
         margin-left: 5px;
      }
   }
}

.deactivatedProduct {
   .homeGrid-item__top {
      opacity: .25;
   }

   .homeGrid-item__info-main {
      opacity: .25;
   }

   &::after {
      content: "";
      background-image: url("/assets/deactivatedProduct-icon.svg");
      position: absolute;
      height: 42px;
      width: 42px;
      top: 20px;
      right: 20px;
   }
}

.block {
   &-container {
      padding-top: 100px;

      &__copy {
         text-align: center;
      }

      &__row {
         display: flex;
      }
   }
}

#cancel-payment-amount {
   font-size: 20px;
   vertical-align: top;
}

.dollars{
   vertical-align: top;
   font-size: 14px;
}

@media (max-width: 1280px) {
   .homeGrid {
      grid-template-columns: 1fr 1fr 1fr;
   }
}

@media (max-width: 980px) {
   .homeGrid {
      grid-template-columns: 1fr 1fr;
   }

   .homeGrid2 {
      grid-template-columns: 1fr;
   }
}

@media (max-width: 768px) {
   .modalItem-info__nameRow {
      .modalItem-info__actions {
         display: block;
      }
   }

   .block-container__row {

      img {
         width: 50%;
      }
   }

   .termsContainer {
      margin-top: 50px;
   }

   .homeGrid-item-empty {
      height: 100%;
      min-height: 235px;
      margin-bottom: 0;
   }

   .profile-cardHome__icon {
      height: 40px;
      width: 40px;
      border-radius: 40px;

      &:hover {
         transform: none;
      }
   }

   .homeGrid-item__nameOnly {
      line-height: 63px;
   }

   .homeGrid-item__name {
      width: 90px;
   }

   .priceRow {
      flex-direction: column;
   }

   .pro-container__cards {
      flex-direction: column;
      align-items: center;
   }

   .pro-container__cardsItem {
      margin-bottom: 20px;
   }

   .pro-container__faq {
      padding: 30px;
   }

   .page404-cover {
      position: absolute;
      top: 120%;
   }

   .search-header {
      padding-top: 82px;
   }

   .itemTagFade {
      right: 0;
      transform: translateY(-30px);
      width: 60px;
   }

   .navbar-home__sectionDropdown {
      padding: 20px 0;
   }

   .pro-container__hero {
      padding: 0 2rem;
   }

   .pro-container__checkout .okkiLogo {
      position: relative;
   }

   .pro-container__checkoutColumn {
      height: auto;
      margin-top: 30px;
   }

   .pro-container__checkoutForm {
      height: auto;
      box-shadow: none;

      &-box {
         border-top: 1px solid #eee;
         padding-top: 20px;

         .modalItem-info__button {
            display: flex !important;
         }
      }
   }

   .search-header__breadcrumb {
      margin-bottom: 20px
   }

   .home-gallery__headerSelects {
      justify-content: space-between;
   }

   .home-gallery__headerSelectsMini {
      margin-top: 20px !important;
   }

   .home-gallery__filters-list {
      width: 90%;
      overflow: scroll;
      justify-content: flex-start;

      a {
         white-space: nowrap;
      }
   }

   .home-gallery__filters-corner {
      &:nth-child(1) {
         display: none;
      }
   }

   .draftHeader {
      padding: 20px;
   }

   .home-cover__profilesResult {
      overflow-x: scroll;

      &::-webkit-scrollbar {
         display: none;
      }

      .profile-card {
         margin-right: 15px;
      }
   }

   .okkiLogoModal {
      display: block;
   }

   .home-cover__title {
      font-size: 28px;
      line-height: 32px;
      text-align: center;
   }

   .home-cover__subtitle {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #66666A;
   }

   .profile-card__icon {
      height: 60px;
      width: 60px;
      border-radius: 60px;
   }

   .profile-card__name {
      width: 60px;
   }

   .home-gallery__headerResults {
      flex-direction: column;
      padding: 20px 0;

      .inputSelect:nth-child(1) {
         margin-left: 0;
      }
   }

   .navbar-home__searchTags {
      display: none;
   }

   .profile-card {
      margin-right: 0;

      svg {
         margin-bottom: 10px;
      }
   }

   .home-cover {
      padding: 130px 20px 50px;
      .columns {
         text-align: center;
      }
      &__img {
         margin-bottom: 50px;
         width: 100%;
      }

      &__profiles {
         justify-content: center;
         width: 90%;
         margin: 40px auto 0;

         .profile-card__icon {
            height: 40px;
            width: 40px;
            border-radius: 40px;
         }
      }
   }

   .rowCenter {
      display: flex;
      flex-direction: column-reverse;
   }

   .home-gallery__header {
      flex-direction: column;
      margin-bottom: 0;

      &-div {
         text-align: center;
         margin-bottom: 40px;

         .inputSelect {
            float: none;
            margin: auto;
            width: 100%;
         }
      }

      select {
         float: none;
      }
   }

   .homeGrid-item__info {
      p {
         overflow: hidden;
         white-space: nowrap;
         text-overflow: ellipsis;
         width: 90px;
      }
   }

   .about-container {
      padding: 3rem 0;

      &__cover {
         display: flex;
         align-items: center;
         justify-content: center;
         margin-top: 50px;

         img {
            margin-right: 0;
            margin-top: 50px;
            width: 85%;
         }
      }

      &__infoBox {
         border: 1px solid #e8e8ee;
         border-radius: 6px;
         margin: 0 auto;
         margin-top: 5rem;
         max-width: 360px;
         width: 100%;
      }
   }

   .RMM__container--is-active {
      margin-top: 0;
   }

   .modalItem-cover {
      background: none !important;
      height: auto !important;
      margin-top: 82px;

      img {
         margin: 0 auto;
         max-width: none;
         width: 100%;
      }
   }

   .RMM__body--no-padding {
      padding: 0 !important;
      position: absolute !important;
   }

   .rowLightbox {
      flex-direction: column;
   }

   .homeGrid {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
   }

   .modalCloseButton {
      top: 10px;
   }

   .modalItem-info__button {
      display: none !important;
   }

   .modalItem-info__buttonMobile {
      display: flex;
      margin-top: 0;
      margin-bottom: 20px;
   }

   .modalItem-info {
      padding: 20px;
      padding-bottom: 50px;
   }

   .modalItem-info__price {
      margin-bottom: 20px;
   }

   .RMM__body {
      height: 100vh;
      overflow: scroll;
   }

   .modalItem-info__logo {
      display: none;
   }

   .search-header__tags {
      overflow-x: scroll;
      padding: 20px;
   }

   .search-header__profiles {
      justify-content: flex-start;
      margin-bottom: 10px;

      .profile-card__name {
         display: none;
      }
   }

   .search-header__breadcrumbAlt {
      margin-bottom: 20px;
   }

   .profile-card__large {
      margin-bottom: 15px;
   }

   .modalItem-cover__sliderContainer {
      width: 100%;

      .slick-prev {
         left: 15px;
         width: 42px;
         height: 42px;
         z-index: 9999;
      }

      .slick-next {
         right: 15px;
         width: 42px;
         height: 42px;
         z-index: 9999;
      }
   }

   // .scrollTop {
   //    display: none !important;
   // }
   

   .homeGrid {
      &-item__top:hover {

         .homeGrid-item__cover {
            transform: none;
         }

         .homeGrid-item__coverOverlay {
            transform: none;
         }
      }
   }

   .homeGrid-item__info {
      align-items: flex-start;
   }

   .modalItem-coverBlur {
      backdrop-filter: none;
   }

   .homeGrid-item {
      margin-bottom: 10px;
   }
}

@media (max-width: 600px) {
   .home {
      &-cover {
         &__title {
            margin-right: 0;
         }
         &__subtitle {
            margin-right: 0;
         }
      }
   }
   .homeGrid-item {
      width: 100%;

      img {
         width: 100%;
      }
   }

   .homeGrid-item__icon {
      width: 30px !important;
   }
}

.profileResultRow {
   div:nth-child(odd) {
      margin-left: 0;
   }
}