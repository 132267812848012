.navbar {
    &-home {
        background: #fff;
        align-items: center;
        display: flex;
        height: 82px;
        justify-content: space-between;
        padding: 0 32px;

        &__search {
            background-position-x: 15px;
            background-position-y: 50%;
            background-repeat: no-repeat;
            margin-bottom: 0;
            padding-left: 40px !important;
            transition: all 0.4s ease;
            width: 100%;

            &:focus {
                border: 1px solid #fff !important;
            }
        }

        &__search-icon {
            position: absolute;
            left: 15px;
            top: 9px;
        }

        &__search-cross {
            position: absolute;
            right: 15px;
            top: 9px;
            opacity: 0;
        }

        &__search-crossHover {
            opacity: 1;
        }

        &__search-iconHover {
            path {
                stroke: #111;
            }
        }

        &__searchExp {
            background-position-x: 15px;
            background-position-y: 50%;
            background-repeat: no-repeat;
            margin-bottom: 0;
            padding-left: 40px !important;
            width: 100%;
        }

        &__searchTags {
            position: absolute;
        }

        &__section {
            width: 30%;
            display: flex;
            align-items: center;
            transition: all 0.4s ease;

            .inputSelect {
                margin-right: 20px;
            }

            form {
                width: 100%;
            }

            input[type="search"]::-webkit-search-cancel-button {
                -webkit-appearance: none;
                cursor: pointer;

                height: 20px;
                width: 20px;
                background-image: url("../images/cancelx.svg");
            }
        }

        &__sectionReduced {
            //    width: 0% !important;
            opacity: .25;
            align-items: center;
            transition: all 0.4s ease;

            .inputSelect {
                margin-right: 20px;
            }

            .inputSelect__control {}
        }

        &__section2 {
            width: 40%;
            position: relative;
            //    justify-content: flex-end;
            transition: all 0.4s ease;

            input[type="text"] {
                transition: all 0.4s ease;
            }
        }

        &__sectionExp {
            width: 40%;
            margin: auto;
            display: flex;
            align-items: center;
            transition: all 0.4s ease;

            .inputSelect {
                margin-right: 20px;
            }
        }

        &__sectionDropdown {
            border-radius: 6px;
            position: absolute;
            top: 60px;
            width: 100%;
            background: #fff;
            padding: 20px 40px;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease;

            li {
                cursor: pointer;
            }

            .itemTag {
                display: flex;
                align-items: center;
                height: 28px;
            }

            &-title {
                font-size: 12px;
                color: #B3B3B4;
                margin-bottom: 20px;
            }

            &-searched {
                ul {
                    display: flex;
                    list-style: none;
                    margin-bottom: 20px;
                    justify-content: center;
                }

            }

            &-suggest {
                ul {
                    list-style: none;

                    p {
                        font-size: 14px;
                        color: #66666A;
                    }
                }

            }

            &-new {
                ul {
                    list-style: none;
                }

                li {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                }
            }

            &-profile {
                align-items: center;
                display: flex;
            }

            &-profileName {
                font-family: $font-semibold;
                font-size: 16px;
                line-height: 20px;
                color: #111;
            }
        }

        &__sectionDropdownActive {
            visibility: visible;
            opacity: 1;
            transition: all 0.3s ease;
        }

        &__actions {
            padding-right: 50px;
            justify-content: flex-end;

            select {
                margin-bottom: 0;
                margin-right: 20px;
                width: 160px;
            }
        }

        &__menuIcon {
            cursor: pointer;
            margin-left: 20px;
        }
    }

    &-homeFocused {
        background: transparent;
        position: relative;
        z-index: 3;
        justify-content: center;
    }

    &-iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        width: 42px;
        margin-left: 20px;

        position: absolute;
        right: 20px;

        .modalCloseButton {
            top: 0;
            right: 0;
        }
    }

    &-iconMenu {
        height: auto;
        width: auto;

        div {
            background: #111;
            border-radius: 5px;
            height: 2px;
            width: 16px;
            display: block;
            margin-bottom: 3px;
            transition: 600ms ease;

            &:first-of-type,
            &:last-of-type {
                transform: translateX(0);
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        // &:hover div {
        //     &:first-of-type {
        //         transform: translateX(0.4rem);
        //     }

        //     &:last-of-type {
        //         transform: translateX(-0.4rem);
        //     }
        // }
    }

    &-profile {
        height: 30px;
        width: 30px;
    }
}

.okkiLogo {
    position: fixed;
    z-index: 9999;
    left: 0;
}

.navbarHideShow {
    background: #fff;
    border-bottom: 1px solid #eee;
    position: fixed;
    width: 100%;
    transition: all 0.5s ease;
    top: 0;
    z-index: 9998;
}

#menuContainer1,
#menuContainer2 {
    display: none;
    transition: all .5s ease;
}

.navbarHideShow2 {
    background: transparent;
    position: fixed;
    width: 100%;
    transition: all 0.5s ease;
    top: 0;
    z-index: 9998;

    .navbar-home {
        background: transparent;
    }
}

.navbarHideShow-hidden {
    top: -122px;
}

.navbarHideShow-border {
    border-bottom: 1px solid #fff;

}

.navbarFixed {
    align-items: center;
    display: flex;
    height: 82px;
    justify-content: space-between;
    padding: 0 32px;
    position: absolute;
}

#navbarOverlay {
    width: 100% !important;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
}

.navbarOverlay {
    background: transparent;
    // z-index: 9999;    transition: all .5s ease;
    overflow: hidden;
    transition: all 1s ease;
}

.navbarOverlay-active {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
}

.navbarOverlayMobile-active {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 0;
    transition: all .5s ease;
}

.footer {
    border-top: 1px solid #eee;
    margin-top: 50px;
    padding: 80px 0;

    &-title {
        font-family: $font-semibold;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 1.5rem;
    }

    &-subtitle {
        font-family: $font-semibold;
        font-size: 14px;
        margin-bottom: 20px;
    }

    &-menu {
        list-style: none;
        color: #000 !important;

        a {
            color: #000 !important;

            &:hover {
                color: #888 !important;
            }
        }
    }

    .itemBadge {
        border: 1px solid #111;
        border-radius: 6px;
        padding: 2px 5px;
        transition: .3s ease;

        &:hover {
            background: #111;
            color: #fff !important;
        }
    }

    &-column {
        width: 50%;
    }

    &-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        text-align: center;
        width: 100%;
    }

    &-signature {
        color: #999;
        display: block;
        font-size: 14px;
        text-align: center;
        width: 100%;
    }

    &-social {
        a {
            color: #acacb5 !important;
            margin-left: 20px;

            &:hover {
                color: $primary-color !important;
            }
        }
    }
}

.overlay {
    height: 100%;
    width: 0;
    max-width: 580px !important;
    position: fixed;
    z-index: 1001;
    right: 0;
    top: 0;
    background-color: rgba(29, 38, 71, 0);
    overflow: hidden;
    transition: all 1s ease;
    max-width: 100% !important;
}

.overlay-content {
    position: relative;
    text-align: left;
    padding: 30% 25%;
    background: #fff;
    max-width: 580px;
    height: 100%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 0.25s ease;

    &-buy {
        &:hover>.overlay-content {
            background-color: #95983a;
        }
    }

    ul {
        list-style: none;

        li {
            margin-bottom: 0;
        }
    }

    &-title {
        padding-top: 0 !important;
        line-height: 32px;

        a {
            font-size: 20px !important;
            font-family: $font-regular !important;

            &:hover {
                opacity: .5;
            }
        }
    }

    &__menuMin {
        .enter {
            color: #111;
            opacity: 0.5;

            &:hover {
                color: #111;
                opacity: 1;
            }
        }
    }

    h1 {
        font-size: 28px;
        font-weight: 600;
        line-height: 36px;
        color: #fff;
        margin-bottom: 25px;
    }



    p {
        margin-bottom: 50px;
    }

    a {
        color: #fff;
        font-family: $font-semibold;
        display: inline-block;
    }

    label {
        font-weight: normal;
        font-size: 13px;
        color: #9aa1b9;
        margin-bottom: 10px;
    }

    &__btnRow {
        display: flex;
        margin-bottom: 20px;
        margin-top: 10px;

        button {
            font-size: 14px;
            margin-right: 15px;
            height: 36px;
        }
    }

    &__btnRowAlt {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    &__inputRow {
        width: 50%;
        max-width: 275px;

        button {
            font-size: 14px;
            margin-right: 15px;
            height: 36px;
        }
    }
}

.overlay-contentAlt2 {
    position: relative;
    text-align: left;
    padding: 30% 25%;
    background: #fff;
    max-width: 580px;
    height: 100%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.25s ease;

    &-buy {
        &:hover>.overlay-content {
            background-color: #95983a;
        }
    }

    &-pro {
        align-items: center;
        display: flex !important;

        svg {
            margin-left: 10px;
        }

        a {
            color: #FF3C14 !important;
        }
    }

    ul {
        list-style: none;

        li {
            margin-bottom: 0;
        }
    }

    &-title {
        padding-top: 0 !important;
        line-height: 32px;

        a {
            font-size: 20px !important;
            font-family: $font-regular !important;

            &:hover {
                opacity: .5;
            }
        }
    }

    &__menuMin {
        .enter {
            color: #fff;
            opacity: 0.5;

            &:hover {
                color: #fff;
                opacity: 1;
            }
        }
    }

    h1 {
        font-size: 28px;
        font-weight: 600;
        line-height: 36px;
        color: #fff;
        margin-bottom: 25px;
    }



    p {
        margin-bottom: 50px;
    }

    a {
        color: #fff;
        font-family: $font-semibold;
        display: inline-block;

        &:hover {
            opacity: .5;
        }
    }

    label {
        font-weight: normal;
        font-size: 13px;
        color: #9aa1b9;
        margin-bottom: 10px;
    }

    &__btnRow {
        display: flex;
        margin-bottom: 20px;
        margin-top: 10px;

        button {
            font-size: 14px;
            margin-right: 15px;
            height: 36px;
        }
    }

    &__btnRowAlt {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    &__inputRow {
        width: 50%;
        max-width: 275px;

        button {
            font-size: 14px;
            margin-right: 15px;
            height: 36px;
        }
    }
}

.overlayBackoffice {
    height: 100%;
    width: 0;
    max-width: 280px !important;
    position: absolute;
    z-index: 9999;
    right: 0;
    top: 0;
    background-color: rgba(29, 38, 71, 0);
    overflow: hidden;
    transition: all 1s ease;

    &-content {
        background: #111;
        padding: 40px;
        padding-top: 80px;
    }

    .closebtn {
        top: 5px !important;
        right: 22px !important;
    }
}

.overlay-contentAlt {
    margin-top: 100px;
}

.overlay-contentSml {
    font-size: 25px !important;
}

.overlay a {
    cursor: pointer;
    padding-left: 0;
    padding-bottom: 5px;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 400;
    color: #111;
    display: block;
    transition: 0.3s;
    z-index: 9999;
}

.overlay a:focus {
    color: #fff;
}

.overlay a:hover {
    color: #111;
    opacity: .5;
}

.overlay .closebtn {
    opacity: 1;
    position: absolute;
    top: 15px;
    right: 28px;
    transition: all 0.25s ease;

    rect {
        fill-opacity: 0;
        transition: all .25s ease;

    }

    &:hover {

        rect {
            fill-opacity: 0.1;
        }
    }
}



.okkiLogoMobile {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.okkiSearchMobile {
    display: none;
    position: absolute;
}

.navbar-mobileSearch {
    display: none;
    position: relative;
}

.draftHeader {
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    height: 52px;
    align-items: center;
    justify-content: center;
    margin-top: 82px;
    position: absolute;
    width: 100%;
    z-index: 1000;

    p {
        color: #fff;
        margin-left: 10px;
        margin-right: 40px;
    }

    &-button {
        border: 1px solid #fff;
        border-radius: 6px;
        color: #fff;
        padding: 5px 10px;
        transition: all .3s ease;

        &:hover {
            background: #fff;
        }
    }

    &-margin {
        position: absolute;
        height: 70px;
    }
}

.draftView {
    position: relative;

    .okkiLogo {
        position: relative;
    }

    &-actions {
        display: flex;

        .button {
            margin-left: 20px;
        }
    }

    &-bottom {
        display: block;
        text-align: center;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
        z-index: 991;
    }

    &-message {
        background: #111;
        border-radius: 10px;
        color: #fff;
        padding: 12px 40px;

    }
}

@media (max-width: 1140px) {
    .navbar-home__section {
        width: 48%;
    }
}

@media (max-width: 980px) {
    .navbar-home__section {
        width: 24%;
    }

    .navbar-home__section2 {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .draftView {
        &-bottom {
            width: 90%;
        }

        &-message {
            padding: 12px;
        }
    }

    .navbar-home__actions {
        display: none;
    }

    .okkiLogoVender {
        display: block !important;

        path {
            fill: #000;
        }
    }

    .footer {
        margin-top: 40px;
        padding: 40px;
        padding-bottom: 100px;
        width: 100%;

        &-bottom {
            margin-top: 15px;
        }
    }

    .footer-column {
        margin-bottom: 1.5rem;
        width: 50%;
        text-align: center;
    }

    .navbar-home__search:focus {
        border: 1px solid #e8e8ee !important;
    }

    .okkiLogo {
        display: none;
    }

    .okkiLogoMobile {
        display: block;
    }

    .okkiSearchMobile {
        display: block;
    }

    .navbar-home {
        padding: 0 20px;
    }

    .navbar-mobileSearch {
        // display: block;
        padding: 0 20px;
        border-top: 1px solid #eee;
        padding-top: 20px;

        &-show {
            display: block;
        }

        &__section {
            width: 100%;
            position: relative;
        }
    }

    .navbar-mobileSearch__section {
        ul {
            justify-content: flex-start;
            overflow-x: scroll;
        }
    }

    .navbar-home__section2 {
        display: none;
    }

    .overlay .closebtn {
        right: 20px;
    }

    .overlay-content {
        padding: 60px;

        #menuContainer1 {
            margin-top: 7vh;
        }


        .inputSelect {
            width: 100%;
        }
    }

    .overlay-contentAlt2 {
        padding: 60px;

        #menuContainer1 {
            margin-top: 7vh;
        }

        &-title a {
            font-size: 18px !important;
        }

        .inputSelect {
            width: 100%;
        }
    }
}