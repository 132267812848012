/* Grid
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.container {
    position: relative;
    width: 95%;
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.column,
.columns {
    width: 100%;
    float: left;
    box-sizing: border-box;
}

.rowFull {
    width: 100%;
}

/* For devices larger than 400px */

@media (min-width: 400px) {
    .container {
        width: 88%;
        padding: 0;
    }
}

/* For devices larger than 550px */

@media (min-width: 768px) {
    .container {
        width: 80%;
    }

    .rowCenter {
        display: flex;
        align-items: center;
    }

    .column,
    .columns {
        margin-left: 4%;
    }

    .column:first-child,
    .columns:first-child {
        margin-left: 0;
    }

    .one.column,
    .one.columns {
        width: 4.66666666667%;
    }

    .two.columns {
        width: 13.3333333333%;
    }

    .three.columns {
        width: 22%;
    }

    .four.columns {
        width: 30.6666666667%;
    }

    .five.columns {
        width: 39.3333333333%;
    }

    .six.columns {
        width: 48%;
    }

    .seven.columns {
        width: 56.6666666667%;
    }

    .eight.columns {
        width: 65.3333333333%;
    }

    .nine.columns {
        width: 74%;
    }

    .ten.columns {
        width: 82.6666666667%;
    }

    .eleven.columns {
        width: 91.3333333333%;
    }

    .twelve.columns {
        width: 100%;
        margin-left: 0;
    }

    .one-third.column {
        width: 30.6666666667%;
    }

    .two-thirds.column {
        width: 65.3333333333%;
    }

    .one-half.column {
        width: 48%;
    }

    /* Offsets */
    .offset-by-one.column,
    .offset-by-one.columns {
        margin-left: 8.66666666667%;
    }

    .offset-by-two.column,
    .offset-by-two.columns {
        margin-left: 17.3333333333%;
    }

    .offset-by-three.column,
    .offset-by-three.columns {
        margin-left: 26%;
    }

    .offset-by-four.column,
    .offset-by-four.columns {
        margin-left: 34.6666666667%;
    }

    .offset-by-five.column,
    .offset-by-five.columns {
        margin-left: 43.3333333333%;
    }

    .offset-by-six.column,
    .offset-by-six.columns {
        margin-left: 52%;
    }

    .offset-by-seven.column,
    .offset-by-seven.columns {
        margin-left: 60.6666666667%;
    }

    .offset-by-eight.column,
    .offset-by-eight.columns {
        margin-left: 69.3333333333%;
    }

    .offset-by-nine.column,
    .offset-by-nine.columns {
        margin-left: 78%;
    }

    .offset-by-ten.column,
    .offset-by-ten.columns {
        margin-left: 86.6666666667%;
    }

    .offset-by-eleven.column,
    .offset-by-eleven.columns {
        margin-left: 95.3333333333%;
    }

    .offset-by-one-third.column,
    .offset-by-one-third.columns {
        margin-left: 34.6666666667%;
    }

    .offset-by-two-thirds.column,
    .offset-by-two-thirds.columns {
        margin-left: 69.3333333333%;
    }

    .offset-by-one-half.column,
    .offset-by-one-half.columns {
        margin-left: 52%;
    }
}

/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    font-weight: normal;
}

h1 {
    font-size: 4rem;
    line-height: 1.2;
}

h2 {
    font-size: 3.6rem;
    line-height: 1.25;
}

h3 {
    font-size: 3rem;
    line-height: 1.3;
}

h4 {
    font-size: 2.4rem;
    line-height: 1.35;
}

h5 {
    font-size: 1.8rem;
    line-height: 1.5;
}

h6 {
    font-size: 1.5rem;
    line-height: 1.6;
}

/* Larger than phablet */

@media (min-width: 550px) {
    h1 {
        font-size: 5rem;
    }

    h2 {
        font-size: 4.2rem;
    }

    h3 {
        font-size: 3.6rem;
    }

    h4 {
        font-size: 3rem;
    }

    h5 {
        font-size: 2.4rem;
    }

    h6 {
        font-size: 1.5rem;
    }
}

p {
    margin-top: 0;
}

/* Links
–––––––––––––––––––––––––––––––––––––––––––––––––– */

a {
    color: #111;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.25s ease;
}

a:hover {
    color: #111;
}

/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.button-square {
    padding: 4px !important;
    width: 28px;
    height: 28px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
        path {
            stroke: #fff;
        }
    }
}

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    font-family: $font-semibold;
    display: flex;
    height: 42px;
    padding: 0 15px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 38px;
    text-decoration: none;
    white-space: nowrap;
    background-color: $primary-color;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;

    span {
        align-items: center;
        color: #fff !important;
        display: flex;
        justify-content: center;
    }
}

.button:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
.button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
    background-color: #333;
    color: #fff;
    outline: none;
}

.button.button-primary,
button.button-primary,
input[type="submit"].button-primary,
input[type="reset"].button-primary,
input[type="button"].button-primary {
    font-family: $font-regular;
    display: flex;
    height: 42px;
    padding: 0 15px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 42px;
    text-decoration: none;
    white-space: nowrap;
    background-color: $primary-color;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;
}

.button.button-primary:hover,
button.button-primary:hover,
input[type="submit"].button-primary:hover,
input[type="reset"].button-primary:hover,
input[type="button"].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type="submit"].button-primary:focus,
input[type="reset"].button-primary:focus,
input[type="button"].button-primary:focus {
    background-color: $primary-color;
    color: #fff;
    outline: none;

    path {
        fill: #fff;
    }
}

.button.button-secondary,
button.button-secondary,
input[type="submit"].button-secondary,
input[type="reset"].button-secondary,
input[type="button"].button-secondary {
    font-family: $font-regular;
    display: flex;
    height: 42px;
    padding: 0 15px;
    color: $primary-color;
    text-align: center;
    font-size: 14px;
    line-height: 42px;
    text-decoration: none;
    white-space: nowrap;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #EEEEEE;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;
}

.button.button-secondary:hover,
button.button-secondary:hover,
input[type="submit"].button-secondary:hover,
input[type="reset"].button-secondary:hover,
input[type="button"].button-secondary:hover,
.button.button-secondary:focus,
button.button-secondary:focus,
input[type="submit"].button-secondary:focus,
input[type="reset"].button-secondary:focus,
input[type="button"].button-secondary:focus {
    background-color: $primary-color;
    color: #fff;
    outline: none;

    path {
        fill: #fff;
    }
}

.button.button-alpha,
button.button-alpha,
input[type="submit"].button-alpha,
input[type="reset"].button-alpha,
input[type="button"].button-alpha {
    font-family: $font-semibold;
    display: flex;
    height: 42px;
    padding: 0 15px;
    color: $primary-color;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    text-decoration: none;
    white-space: nowrap;
    background: transparent;
    border: 1px solid $primary-color;
    border-radius: 6px;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;

    span {
        color: #111 !important;
        font-family: $font-semibold;
    }
}

.button.button-alpha:hover,
button.button-alpha:hover,
input[type="submit"].button-alpha:hover,
input[type="reset"].button-alpha:hover,
input[type="button"].button-alpha:hover,
.button.button-alpha:focus,
button.button-alpha:focus,
input[type="submit"].button-alpha:focus,
input[type="reset"].button-alpha:focus,
input[type="button"].button-alpha:focus {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff;
    outline: none;

    span {
        color: #fff !important;
    }

    path {
        fill: #fff;
    }
}

.button.button-alphaInverted,
button.button-alphaInverted,
input[type="submit"].button-alphaInverted,
input[type="reset"].button-alphaInverted,
input[type="button"].button-alphaInverted {
    font-family: $font-semibold;
    display: flex;
    height: 42px;
    padding: 0 15px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    text-decoration: none;
    white-space: nowrap;
    background: transparent;
    border: 1px solid #fff;
    border-radius: 6px;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;

    span {
        color: #111 !important;
        font-family: $font-semibold;
    }
}

.button.button-alphaInverted:hover,
button.button-alphaInverted:hover,
input[type="submit"].button-alphaInverted:hover,
input[type="reset"].button-alphaInverted:hover,
input[type="button"].button-alphaInverted:hover,
.button.button-alphaInverted:focus,
button.button-alphaInverted:focus,
input[type="submit"].button-alphaInverted:focus,
input[type="reset"].button-alphaInverted:focus,
input[type="button"].button-alphaInverted:focus {
    background-color: #fff;
    border-color: #fff;
    color: #000;
    outline: none;

    span {
        color: #fff !important;
    }

    path {
        fill: #fff;
    }
}

.button.button-alphaAlt,
button.button-alphaAlt,
input[type="submit"].button-alphaAlt,
input[type="reset"].button-alphaAlt,
input[type="button"].button-alphaAlt {
    font-family: $font-semibold;
    display: flex;
    height: 42px;
    padding: 0 15px;
    color: $primary-color;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    text-decoration: none;
    white-space: nowrap;
    background: transparent;
    border: 1px solid #111;
    border-radius: 6px;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;

    span {
        color: #111 !important;
        font-family: $font-semibold;
    }
}

.button.button-alphaAlt:hover,
button.button-alphaAlt:hover,
input[type="submit"].button-alphaAlt:hover,
input[type="reset"].button-alphaAlt:hover,
input[type="button"].button-alphaAlt:hover,
.button.button-alphaAlt:focus,
button.button-alphaAlt:focus,
input[type="submit"].button-alphaAlt:focus,
input[type="reset"].button-alphaAlt:focus,
input[type="button"].button-alphaAlt:focus {
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff;
    outline: none;

    span {
        color: #fff !important;
    }

    path {
        fill: #fff;
    }
}

.button.button-flat,
button.button-flat,
input[type="submit"].button-flat,
input[type="reset"].button-flat,
input[type="button"].button-flat {
    font-family: $font-regular;
    display: flex;
    height: 42px;
    padding: 0 15px;
    color: rgba(255, 255, 255, .5);
    ;
    text-align: center;
    font-size: 14px;
    line-height: 42px;
    text-decoration: none;
    white-space: nowrap;
    background: transparent;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;
}

.button.button-flat:hover,
button.button-flat:hover,
input[type="submit"].button-flat:hover,
input[type="reset"].button-flat:hover,
input[type="button"].button-flat:hover,
.button.button-flat:focus,
button.button-flat:focus,
input[type="submit"].button-flat:focus,
input[type="reset"].button-flat:focus,
input[type="button"].button-flat:focus {
    color: #fff;
    outline: none;

    path {
        fill: #fff;
    }
}

.button.button-loadMore,
button.button-loadMore,
input[type="submit"].button-loadMore,
input[type="reset"].button-loadMore,
input[type="button"].button-loadMore {
    font-family: $font-regular;
    display: flex;
    height: 42px;
    padding: 0 15px;
    color: #888;
    text-align: center;
    font-size: 14px;
    line-height: 42px;
    text-decoration: none;
    white-space: nowrap;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #EEEEEE;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;
}

.button.button-loadMore:hover,
button.button-loadMore:hover,
input[type="submit"].button-loadMore:hover,
input[type="reset"].button-loadMore:hover,
input[type="button"].button-loadMore:hover,
.button.button-loadMore:focus,
button.button-loadMore:focus,
input[type="submit"].button-loadMore:focus,
input[type="reset"].button-loadMore:focus,
input[type="button"].button-loadMore:focus {
    color: $primary-color;

    path {
        fill: #fff;
    }
}

.button.buttonPro,
button.buttonPro,
input[type="submit"].buttonPro,
input[type="reset"].buttonPro,
input[type="button"].buttonPro {
    font-family: $font-semibold;
    display: flex;
    height: 52px;
    padding: 0 15px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 52px;
    text-decoration: none;
    white-space: nowrap;
    background: #FF3C14;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;
    width: 100%;
    max-width: 280px;
    align-items: center;
    justify-content: center;

    svg {
        margin-left: 10px;
    }
}

.button.buttonPro:hover,
button.buttonPro:hover,
input[type="submit"].buttonPro:hover,
input[type="reset"].buttonPro:hover,
input[type="button"].buttonPro:hover,
.button.buttonPro:focus,
button.buttonPro:focus,
input[type="submit"].buttonPro:focus,
input[type="reset"].buttonPro:focus,
input[type="button"].buttonPro:focus {
    background: rgba(255, 60, 20, 0.9);
    color: #fff;
    outline: none;

    path {
        fill: #fff;
    }
}

.button.buttonProAlpha,
button.buttonProAlpha,
input[type="submit"].buttonProAlpha,
input[type="reset"].buttonProAlpha,
input[type="button"].buttonProAlpha {
    font-family: $font-semibold;
    display: flex;
    height: 42px;
    padding: 0 15px;
    color: #FF3C14;
    text-align: center;
    font-size: 14px;
    line-height: 42px;
    text-decoration: none;
    white-space: nowrap;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #FF3C14;
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.25s ease;
    width: 100%;
    max-width: 280px;
    align-items: center;
    justify-content: center;

    svg {
        margin-left: 10px;
    }
}

.button.buttonProAlpha:hover,
button.buttonProAlpha:hover,
input[type="submit"].buttonProAlpha:hover,
input[type="reset"].buttonProAlpha:hover,
input[type="button"].buttonProAlpha:hover,
.button.buttonProAlpha:focus,
button.buttonProAlpha:focus,
input[type="submit"].buttonProAlpha:focus,
input[type="reset"].buttonProAlpha:focus,
input[type="button"].buttonProAlpha:focus {
    background: rgba(255, 60, 20, 0.9);
    color: #fff;
    outline: none;

    path {
        fill: #fff;
    }
}

button:disabled {
    background: #ccc;
    color: #fff;
    cursor: auto;
}

.aButton {
    line-height: 42px;
}

.buttonCut-container {
    margin-left: auto;
    max-width: 400px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.buttonCut {}

.buttonIcon {
    line-height: 42px;

    svg {
        margin-right: 10px;
        margin-bottom: 0 !important;
    }
}

.buttonCenter {
    margin: auto;
}

.buttonArrow {
    align-items: center;

    svg {
        margin-left: 10px;
    }
}

.buttonIconRight {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 125px;

    svg {
        margin-left: 5px;
        margin-bottom: 0 !important;
    }
}

.button-centered {
    margin: auto;
}

button:focus {
    outline: none;
}

.buttonFull {
    justify-content: center;
    width: 100%;
}

.textLink {
    color: #888888;

    &:hover {
        color: $primary-color;
    }
}

/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
    height: 38px;
    padding: 6px 10px;
    /* The 6px vertically centers text on FF, ignored by Webkit */
    background-color: #fff;
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    box-shadow: none;
    box-sizing: border-box;
    font-size: 14px;
    font-family: $font-regular;
}

/* Removes awkward default styles on some inputs for iOS */

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: all 0.25s ease;
}

textarea {
    min-height: 65px;
    padding-top: 6px;
    padding-bottom: 6px;
    resize: none;
}

input[type="email"]:hover,
input[type="number"]:hover,
input[type="search"]:hover,
input[type="text"]:hover,
input[type="tel"]:hover,
input[type="url"]:hover,
input[type="password"]:hover,
textarea:hover,
select:hover {
    border: 1px solid #BBBBBB;
    outline: 0;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
    border: 1px solid #BBBBBB;
    outline: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

::placeholder {
    color: #bbb;
}

.tooltipStyle {
    background: rgba(8, 16, 39, 0.95) !important;
    border-radius: 3px !important;
    font-size: 12px !important;
    line-height: 120% !important;
    padding: 6px 10px !important;
}

label,
legend {
    display: block;
    font-family: $font-regular;
    font-size: 14px;
    line-height: 15px;
    color: #888;
    margin-bottom: 10px;
}

fieldset {
    padding: 0;
    border-width: 0;
}

input[type="checkbox"],
input[type="radio"] {
    display: inline;
}

label>.label-body {
    display: inline-block;
    margin-left: 0.5rem;
    font-weight: normal;
}

input:disabled {
    background: rgba(238, 238, 238, 0.5);
    border: 1px solid #eee;
    color: #888;
}

.inputError {
    border: 1px solid #FF5555 !important;
}

.inputFile-container {
    position: relative;
}

.inputFile {
    /* visibility: hidden etc. wont work */
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputFile+label {
    border: 1px solid #000000;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    padding: 5px 10px;
    transition: .3s ease;
    margin-bottom: 0;

    /* "hand" cursor */
    &:hover {
        background: #000;
        color: #fff;
    }
}


.inputFile+label * {
    pointer-events: none;
}

.inputSearch {
    background-image: url("../images/search_icon.svg");
    background-repeat: no-repeat;
    background-position-x: 5%;
    background-position-y: center;
    padding: 6px 10px 6px 35px !important;
}

.inputSubcategory {
    background-image: url("../images/arrow_icon.svg");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center;
    padding: 6px 10px 6px 35px !important;
    width: 100%;
}

select {
    cursor: pointer;
}

.inputSelect {
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    background-image: url("/images/select_icon.svg");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: center;
    cursor: pointer;
    width: 150px;
    height: 38px;

    &-backoffice {
        margin-left: 20px;
    }

    input {
        height: auto;
    }

    &__control {
        border-color: #e8e8ee !important;
        box-shadow: none !important;
        cursor: pointer;
        height: 36px;
        z-index: 999 !important;
    }

    &__indicators {
        cursor: pointer;
    }

    &__single-value {
        color: #111 !important;
    }

    &__value-container {
        cursor: pointer;
        height: 36px;
    }

    &__indicator-separator {
        background-color: #fff !important;
    }

    &__menu {
        position: absolute;
        padding: 0 5px;
        z-index: 9999 !important;
        width: 200px;
        max-height: 300px;
        overflow-y: scroll;
    }

    &__option {
        border-radius: 6px;
    }
}

.inputSelectSearch {
    margin: 20px 0;
    width: 100% !important;
}

.inputSelectMini {
    width: 80px !important;
}

.inputSelectFull {
    width: 100%;
}

.Select.is-open {
    position: relative;
    z-index: 1000;
}

.inputSelect__control--menu-is-open {
    position: relative;
    z-index: 1000;
}

.customSelectNav {
    margin-left: 10px;

    &__control {
        border: 1px solid #111 !important;
    }

    &__indicator-separator {
        background: 0 !important;
    }

    &__control--is-focused {
        border: 1px solid #111 !important;
        box-shadow: none !important;
    }

    &__input {
        input {
            height: auto;
            margin-bottom: 0;
        }
    }

    &__menu {
        background: #111 !important;
        border-radius: 8px;
        padding: 10px;
    }

    &__option {
        border-radius: 4px;
        color: #fff !important;
        text-align: left !important;
    }
}

.customSelect {
    margin-left: 10px;

    &__control {
        border: 1px solid #eee !important;
    }

    &__indicator-separator {
        background: 0 !important;
    }

    &__control--is-focused {
        border: 1px solid #111 !important;
        box-shadow: none !important;
    }

    &__input {
        input {
            height: auto;
            margin-bottom: 0;
        }
    }

    &__menu {
        background: #111 !important;
        border-radius: 8px;
        padding: 10px;
        text-align: left !important;
    }

    &__option {
        border-radius: 4px;
        color: #fff !important;
        text-align: left !important;
    }
}

.inputWrong {
    border: 1px solid #f84141 !important;
}

.fieldError {
    font-size: 12px;
    line-height: 15px;
    color: #f84141;
    margin-bottom: 10px;
    margin-top: -10px;
}

.fieldSuccess {
    font-size: 12px;
    line-height: 15px;
    color: #43A047;
    margin-bottom: 10px;
}

.formAgreement {
    color: #555;
    font-size: 12px;

    a {
        color: #111;
        font-family: $font-regular;
    }
}

.title {
    font-family: $font-semibold;
    font-size: 2rem;
    line-height: 120%;
    color: #292525;
}

.titleMin {
    font-family: $font-semibold;
    font-size: 1.25rem;
    line-height: 120%;
    color: #111;
    border-bottom: 30px;
}

.titleMinSub {
    font-family: $font-semibold;
    font-size: 1rem;
    line-height: 120%;
    color: #111;
}

.titleMinAlt {
    font-family: $font-semibold;
    font-size: 1.25rem;
    line-height: 120%;
    color: #ACACB5;
}

.subtitle {
    font-weight: normal;
    font-size: 22px;
    line-height: 120%;
    color: #111;
}

.itemTagFade {
    display: inline;
    height: 40px;
    width: 100px;
    position: absolute;
    transform: translate(-100px, -10px);
    background: linear-gradient(270deg, #FFFFFF 49.02%, rgba(255, 255, 255, 0) 100%);
}

.itemTagButton {
    cursor: pointer;
    font-size: 14px;
    transition: all 0.25s ease;
    background: #ffffff;
    border: 1px solid $primary-color;
    box-sizing: border-box;
    border-radius: 6px;
    color: $primary-color;
    line-height: 0;
    margin-right: 12px;
    padding: 5px 10px;

    &:hover {
        background: $primary-color;
        color: #fff;
    }
}

.itemTag {
    cursor: pointer;
    font-size: 14px;
    transition: all 0.25s ease;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 6px;
    color: #444;
    line-height: 0;
    margin-right: 12px;
    padding: 5px 10px;
    padding-bottom: 6px;


    &:hover {
        background: #fff;
        border: 1px solid #111;
        color: #111;
    }


    &-active {
        background: #111 !important;
        border: 1px solid #111 !important;
        color: #fff !important;
    }

    &-activeAlt {
        background: rgba(172, 172, 181, 0.1);
    }

}

.itemTagShipping {
    cursor: auto;
    line-height: 20px;
    display: inline-table;
    margin-top: 20px;
    font-size: 14px;
    transition: all 0.25s ease;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 6px;
    color: #444;
    margin-right: 12px;
    padding: 5px 10px;
    padding-bottom: 6px;
}

.itemTagPro {
    cursor: pointer;
    font-size: 14px;
    transition: all 0.25s ease;
    background: #ffffff;
    border: 1px solid #111;
    box-sizing: border-box;
    border-radius: 6px;
    color: #111;
    line-height: 100%;
    margin-right: 12px;
    padding: 5px 10px;
    padding-bottom: 6px;
    display: inline-block;
    margin-top: 5px;
}

.itemTagFree {
    cursor: pointer;
    font-size: 14px;
    transition: all 0.25s ease;
    background: #ffffff;
    border: 1px solid #888;
    box-sizing: border-box;
    border-radius: 6px;
    color: #888;
    line-height: 100%;
    margin-right: 12px;
    padding: 5px 10px;
    padding-bottom: 6px;
    display: inline-block;
    margin-top: 5px;
}

.itemTagPrice {
    cursor: pointer;
    font-size: 14px;
    transition: all 0.25s ease;
    background: rgba(255, 161, 20, 0.25);
    box-sizing: border-box;
    border-radius: 6px;
    color: #111;
    line-height: 100%;
    margin-right: 12px;
    padding: 5px 10px;
    padding-bottom: 6px;
    display: inline-block;
    margin-top: 5px;
}

.itemTagBadge {
    background: rgba(238, 238, 238, 0.5);
    border-radius: 50px;
    color: #acacb5;
    cursor: pointer;
    font-size: 14px;
    margin: 0 5px;
    padding: 4px 10px 5px 10px;
    transition: all 0.25s ease;
}

.itemTagBadgeAlt {
    background: rgba(238, 238, 238, 0.5);
    border-radius: 50px;
    color: #66666a;
    cursor: pointer;
    font-size: 14px;
    margin: 0 5px;
    padding: 4px 10px 5px 10px;
    transition: all 0.25s ease;
}

.itemTagTrial {
    background: rgba(255, 161, 20, 0.25);
    border-radius: 6px;
    color: #000;
    font-size: 14px;
    padding: 5px 10px;
}

.itemTagAlt {
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 6px;
    color: #66666a;
    line-height: 0;
    margin-left: 12px;
    padding: 7px;
    display: flex;
    align-items: center;
    height: 28px;

    span {
        margin-left: 5px;
    }
}

.itemTagBtn {
    background: #eef2fb;
    box-sizing: border-box;
    border-radius: 6px;
    color: #3059c8 !important;
    line-height: 0;
    margin-left: 12px;
    padding: 7px;
    display: flex;
    align-items: center;
    height: 28px;
}

/* Lists
–––––––––––––––––––––––––––––––––––––––––––––––––– */

ul {
    list-style: circle inside;
}

ol {
    list-style: decimal inside;
}

ol,
ul {
    padding-left: 0;
    margin-top: 0;
}

ul ul,
ul ol,
ol ol,
ol ul {
    margin: 1.5rem 0 1.5rem 3rem;
    font-size: 90%;
}

li {
    margin-bottom: 1rem;
}

/* Tables
–––––––––––––––––––––––––––––––––––––––––––––––––– */

table {
    border-spacing: 0;
}

th,
td {
    border-bottom: 1px solid #e1e1e1;
    color: #555;
    padding: 12px 15px;
    text-align: left;
}

th {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #999999;
}

th:first-child,
td:first-child {
    padding-left: 0;
}

th:last-child,
td:last-child {
    padding-right: 0;
}

/* Utilities
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.u-full-width {
    width: 100%;
    box-sizing: border-box;
}

.u-max-full-width {
    max-width: 100%;
    box-sizing: border-box;
}

.u-pull-right {
    float: right;
}

.u-pull-left {
    float: left;
}

.u-center {
    text-align: center;
}

.centerFlex {
    align-items: center;
    display: flex;
    justify-content: center;
}

/* Misc
–––––––––––––––––––––––––––––––––––––––––––––––––– */

hr {
    border-width: 0;
    border-top: 1px solid #eee;
    max-width: 100%;
    margin: auto;
    margin-bottom: 40px;
}

.fullDivider {
    max-width: none;
}

.hrMin {
    margin-bottom: 30px;
}

.hrMin20 {
    margin-bottom: 20px;
}

.interIcon {
    font-family: "Inter", sans-serif;
}

.type-dark {
    background: rgba(0, 0, 0, 0.9) !important;
    border-bottom: 5px;
}

.SettingsPrev-cropContainer-1 {
    height: 90% !important;
}

.MuiSlider-root {
    border: 1px solid #fff !important;
    color: #000 !important;
}

.MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, .2) !important;
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, .2) !important;
}

.MuiTypography-overline {
    line-height: 10px !important;
}

.MuiButton-label {
    font-family: $font-semibold;

    svg {
        margin-left: 10px;
    }
}

.MuiButton-contained:hover {
    box-shadow: none !important;
    background-color: #333 !important
}

.NuevoProducto-sliderContainer-4 {
    max-width: 230px;
}

.NuevoProducto-sliderContainer-15 {
    max-width: 230px;
}

.NuevoProducto-sliderLabel-5 {
    img {
        margin-right: 10px;
    }
}

.SettingsPrev-sliderContainer-4 {
    max-width: 230px;
}

.SettingsPrev-sliderLabel-5 {
    img {
        margin-right: 10px;
    }
}

.tags-input {
    overflow: hidden;
}

@media (min-width: 600px) {
    .SettingsPrev-controls-3 {
        align-items: center;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .NuevoProducto-controls-3 {
        align-items: center;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

}

@media (max-width:768px) {
    #productItemModalCrop {
        border-radius: 0 !important;
        height: 100% !important;
        max-height: none !important;
        padding-top: 30px !important;
    }

    .SettingsPrev-controls-3 {
        padding: 20px !important;
    }

    .SettingsPrev-sliderContainer-4 {
        max-width: none;
        margin-right: 10px;
    }

    .NuevoProducto-cropButton-2,
    .SettingsPrev-cropButton-2 {
        margin-top: 20px !important;
    }

    .modalCrop-title {
        margin-bottom: 30px !important;
    }
}

// .tooltipStyle {
//     transform: scale(.75);
// }

// .tooltipStyle.show {
//     transform: scale(1);
// }

// .__react_component_tooltip.show {
//     animation: popup 0.25s !important;
// }

// .__react_component_tooltip.show {
//     animation: popup 0.25s !important;
// }

@keyframes popup {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
}

.__react_component_tooltip::after {
    display: none;
}

.ReactModal__Overlay--after-open {
    z-index: 9999;
}

.ReactModal__Overlay {
    z-index: 999999999;
}

/* Clearing
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* Self Clearing Goodness */

.row:after,
.u-cf {
    content: "";
    display: table;
    clear: both;
}

.scrollTop {
    position: fixed;
    right: 40px;
    bottom: 40px;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.3s;
    transition: all 0.3s ease;
    opacity: 1;

    svg {
        position: absolute;
        cursor: pointer;
        right: 40px;
        &:hover {
            transition: all 0.3s ease;
    
            rect {
                fill: black;
            }
    
            path {
                stroke: white;
            }
        }
    }

    rect,
    path {
        transition: all 0.3s ease;
    }

    
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}