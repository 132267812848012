.profile-cover {
  padding: 40px 0;
}


.rowProfile-bottom__tags {}

.rowProfile {
  margin: auto;
  max-width: 760px;

  &-header {
    display: flex;
  }

  &-headerInfo {
    width: 100%;
    margin-left: 40px;
  }

  &-bottom {
    margin-bottom: 40px;

    .itemTagAlt {
      margin-left: 0;
      margin-right: 12px;
      margin-bottom: 10px;
    }

    &__tags {


      p:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  &-logo {
    display: flex;
    align-items: center;

    &__brand {
      display: flex;
      align-items: center;
    }
  }

  &-logoEmpty {
    background: #DDDDDD;
    height: 20px;
    width: 100%;
  }

  &-subEmpty {
    background: #DDDDDD;
    height: 20px;
    width: 100%;
    max-width: 260px;
  }

  &-listEmpty {
    background: #DDDDDD;
    height: 20px;
    width: 60%;
    margin: auto;
    max-width: 760px;
  }

  &-productEmpty {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .rowProfile-subEmpty {
      margin-bottom: 10px;
    }
  }

  &-aboutEmpty {
    margin-left: 0 !important;

    .rowProfile-logoEmpty {
      margin-bottom: 10px;
    }
  }

  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      height: 28px;
      line-height: 14px;
      justify-content: center;
      margin-left: 10px;
    }
  }

  &-thumb {
    height: 60px;
    width: 60px;
    border-radius: 100px;
  }

  &-thumbPro::after {
    content: '';
    background: url('../images/pro_badge.svg');
    background-position: center center;
    background-size: cover;
    height: 18px;
    width: 18px;
    position: absolute;
    margin-left: -17px;
    margin-top: 42px;
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    width: 50%;

    a {
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      border-radius: 6px;
      line-height: 0;
      margin-left: 12px;
      padding: 5px;
      height: 32px;
      width: 32px;

      &:hover {
        background: #111;
        border: 1px solid #111;

        path {
          fill: #fff;
        }
      }
    }
  }

  &-about {
    color: #111;
    margin: 20px 0;
    display: block;
    word-break: break-word;
  }

  &-title {
    font-family: $font-semibold;
    font-size: 25px;
    color: #000000;
    max-width: 360px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.copiedPop {
  display: none;
  opacity: 0;
  z-index: 9999;
}

.copiedPop-show {
  display: block;
  opacity: 1;
}

.copiedPop-exit {
  animation: disappear 1s 1 alternate ease-out forwards;
  animation-delay: 5000ms;
  animation-fill-mode: forwards;
}

@keyframes disappear {
  0% {
    opacity: 1;
  }

  60% {
    opacity: .5;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

.RMM__body {
  background: #fff;
  transition: opacity 0.25s ease !important;
}

.RMM__close-button {
  background: none !important;
}

.RMM__close-button::after,
.RMM__close-button::before {
  background: #111 !important;
}

.rowLightbox {
  .five {
    margin: auto;
  }
}

.modalCloseButton {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  //transition: all .3s ease;

  rect {
    transition: all .3s ease;
  }

  &:hover {
    opacity: .3;
    // rect {
    //   fill-opacity: .3;
    // }
  }
}

.rowProfile-responsive {
  &__title {
    justify-content: space-between;
    margin-top: 20px;
  }

  &__hide {}
}

.helpModal {
  background: #111;
  border: 1px solid #EEEEEE;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 40px 0;

  position: fixed;
  bottom: 100px;
  right: 40px;
  width: 300px;
  z-index: 1000;

  hr {
    margin-bottom: 20px;
    margin-top: 20px;
    border-top: 1px solid #222222;
  }

  ul {
    color: #888;
    list-style: none;
    padding: 0 40px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    margin-bottom: 20px;
  }

  li {
    margin: 1rem 0;
  }

  h5 {
    display: block;
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #fff;
    padding: 0 40px;

  }

  &-hidden {
    display: none !important;
  }
}

.cookiesModal {
  background: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 30px;
  border: 1px solid #EEEEEE;
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 230px;
  z-index: 9997;

  img {
    margin-bottom: 10px;
  }

  ul {
    color: #888;
    list-style: none;
    padding: 0 40px;
  }

  p {
    color: #000;
    font-size: 14px;
    line-height: 20px;
  }

  a {
    color: #888;
  }

  li {
    margin: 1rem 0;
  }

  h5 {
    display: block;
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #fff;
    padding: 0 40px;

  }

  &-close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &-hidden {
    display: none !important;
  }
}

.noProModal {
  background: #fff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 30px;
  border: 1px solid #EEEEEE;
  position: fixed;
  bottom: 80px;
  right: 40px;
  width: 240px;
  z-index: 1000;

  hr {
    margin-bottom: 20px;
    margin-top: 20px;
    border-top: 1px solid #222222;
  }

  ul {
    color: #888;
    list-style: none;
    padding: 0 40px;
  }

  p {
    color: #000;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    span {
      font-family: $font-semibold;

    }
  }

  li {
    margin: 1rem 0;
  }

  h5 {
    display: block;
    font-family: $font-semibold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #fff;
    padding: 0 40px;

  }

  &-close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;

    path {
      transition: all .3s ease;
    }

    &:hover {
      path {
        stroke: #888;
      }
    }
  }

  &-hidden {
    display: none !important;
  }
}


.helpButton {
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  right: 40px;

  #helpButtonPath {
    transition: all .25s ease;
  }

  &:hover {
    #helpButtonPath {
      fill: #111;
    }
  }
}

.statsContainer {
  hr {
    max-width: none;
  }

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
  }

  &-grid__item {
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
    border-radius: 6px;
    height: 287px;
    padding-bottom: 20px;

    hr {
      max-width: none;
      margin-bottom: 0;
    }

    &-head {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
    }

    &-graph {
      align-items: center;
      display: flex;
      height: calc(100% - 60px);
      justify-content: center;
    }

    &-row {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
      padding-bottom: 0;

      
    }
  }
}

.homeGrid-stats__info {
  justify-content: space-between;
  margin-top: 10px;

  div {
    width: 45%;

    p:nth-child(1) {
      color: #999;
      font-size: 12px;
    }

    p:nth-child(2) {

      color: #111;
    }
  }
}

.homeGrid-stats__title {
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  color: #111;
  
}

.sortList {
  &-input {
    width: 234px;
  }

  &-close {
    position: absolute;
    right: 0;
    height: 18px;
  }

  &-button {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  ul {
    list-style: none;
  }

  svg {
    cursor: pointer;
    margin-right: 10px;
  }

  li {
    display: flex;
    align-items: center;
    padding: 5px 0;
    position: relative;
  }
}

@media (max-width: 768px) {
  .noProModal {
    display: none;
  }
}

@media (max-width: 600px) {
  .rowProfile {
    &-header {
      align-items: center;
      flex-direction: column;
    }
    &-top{
      display: unset;
    }
    &-logo {
      justify-content: center;
    }
    &-title {
      margin-bottom: 20px;
    }

  }
    
  .rowProfile-headerInfo {
    margin-left: 0;
  }

  .profile-cover {
    padding: 40px;
  }

  .rowProfile-responsive {

  }

  .rowProfile-responsive__title {
    justify-content: center;
    display: flex;
    
  }

  .rowProfile-bottom {
    flex-wrap: wrap;
    justify-content: space-between;
   

    // flex-direction: column;
    &__tags {

      margin-bottom: 20px;
      .itemTagAlt:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .rowProfile-thumb {
    height: 60px;
    width: 60px;
    margin-right: 0;
    border-radius: 60px;
    margin-bottom: 20px;
    
  }

  .rowProfile-thumbPro {
    &::after {
      content: '';
      background: url('../images/pro_badge.svg');
      background-position: center center;
      background-size: cover;
      height: 24px;
      width: 24px;
      position: absolute;
      margin-left: -20px;
      margin-top: 40px;
    }

  }

  .rowProfile-actions {
    justify-content: center;
    width: 100%;
  }

  .rowProfile-about {
    margin-top: 20px;
  }

  .rowProfile-placeholder {
    .rowProfile-logoEmpty {
      width: 60%;
      margin: auto;
    }

    .rowProfile-actions {
      display: none;
    }
  }
}

