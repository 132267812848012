.customLand {
  height: 100vh;
  padding: 30px;
  position: relative;

  &-intro {
    height: calc(100vh - 60px);
  }

  &-guyMobile {
    display: none;
  }

  &-bubbles {
    opacity: 0;
    transform: scale(0);
    transition-delay: 1s;
  }

  &-bubbles2 {
    opacity: 0;
    transform: scale(0);
    margin-bottom: -60px;
    margin-left: 130px;
    transition-delay: 3s;
  }

  &-title {
    font-style: normal;
    font-weight: normal;
    font-size: 68px;
    line-height: 112%;
    color: #111;
    max-width: 910px;
    width: 90%;

    div {
      display: inline;
    }
  }

  &-subtitle {
    margin-top: 30px;
    font-size: 16px;
    line-height: 140%;
    color: #878787;
    max-width: 440px;
  }

  &-button {
    position: fixed;
    right: 30px;
    z-index: 1;
    align-items: center;
  }

  &-cover {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 30px;
    bottom: 30px;
  }

  &-buttonApply {
    align-items: center;
    background: $primary-color;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: $font-semibold;
    font-size: 24px;
    justify-content: center;
    height: 180px;
    width: 180px;
    text-align: center;
    position: absolute;
    bottom: 30px;
    transition: all 0.25s ease;

    &:hover {
      animation: none;
      transform: rotate(0deg);
    }
  }

  &-form {
    height: calc(100vh - 90px);
    margin-top: 30px;
    max-width: 440px;

    h5 {
      font-weight: normal;
      font-size: 22px;
      line-height: 120%;
      color: #111;
    }

    button {
      display: block;
      font-family: $font-regular;
      font-size: 16px;
      height: 52px;
      color: #ffffff;
      width: 100%;
    }

    input {
      height: 42px;
    }

    a {
      color: $primary-color;
      transition: all 0.25s ease;

      &:hover {
        color: #000;
      }
    }
  }

  &-formThanks {
    height: calc(100vh - 100px);
    max-width: 440px;

    h5 {
      font-weight: normal;
      font-size: 22px;
      line-height: 120%;
      color: #111;
    }

    a {
      color: $primary-color;
      transition: all 0.25s ease;

      &:hover {
        color: #000;
      }
    }
  }

  &-footer {
    margin-top: 30px;
    padding-bottom: 30px;

    ul {
      display: flex;
      list-style: none;
    }

    li {
      font-size: 14px;
      line-height: 18px;
      color: #878787;
      margin-bottom: 0;
      margin-right: 30px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }

    a {
      font-size: 14px;
      line-height: 18px;
      color: #878787;
      transition: all .25s ease;

      &:hover {
        color: #111;
      }
    }
  }
}

.customLand2 {
  background: #fff;
  height: 100vh;
  position: absolute;
  width: 100%;
  transition: background 1s ease;

  .fieldError {
    color: #fff;
    text-align: left;
    margin-top: -5px;
    margin-bottom: 10px;
  }

  ::-moz-selection {
    /* Code for Firefox */
    color: #000;
    background: #D6F2EC;
  }

  ::selection {
    color: #000;
    background: #D6F2EC;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #FF8A72 !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #FF8A72 !important;
  }

  ::placeholder {
    color: #FF8A72 !important;
  }

  &-bg {
    background: #FF3C14;
  }

  &-intro {
    height: 100vh;
    min-height: 720px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    &__hide {
      display: none;
    }

    &__text {
      opacity: 0;
      height: 100vh;
      display: flex;
      flex-direction: column;
      position: absolute;
      justify-content: center;
    }
  }

  &-introRed {
    background: #FF3C14;

  }

  &-nav {
    height: 120px;
    justify-content: space-between;
    position: fixed;
    left: 0;
    display: flex;
    width: 100%;
    top: 0;
    align-items: center;
    margin: auto;
    padding: 0 80px;
    z-index: 99;
  }

  &-navAlt {
    height: 120px;
    justify-content: space-between;
    position: fixed;
    left: 0;
    display: flex;
    width: 100%;
    top: 0;
    align-items: center;
    margin: auto;
    padding: 0 80px;
    z-index: 99;
  }

  &-nav2 {
    display: none;
  }

  &-navItem {
    width: 33%;
    display: flex;

    a {

      &:hover {
        text-decoration: underline;
      }
    }

    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(3) {
      justify-content: flex-end;
    }

    &-responsive {
      display: none;
    }
  }

  &-navItemAlt {
    width: 33%;
    display: flex;

    a {
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }

    &:nth-child(2) {
      justify-content: center;
    }

    &:nth-child(3) {
      justify-content: flex-end;
    }
  }

  .selected {
    color: #fff;
  }

  &-guyMobile {
    display: none;
  }

  &-bubbles {
    opacity: 0;
    transform: scale(0);
    transition-delay: 1s;
  }

  &-bubbles2 {
    opacity: 0;
    transform: scale(0);
    margin-bottom: -60px;
    margin-left: 130px;
    transition-delay: 3s;
  }

  &-title {
    font-family: $font-semibold;
    font-weight: normal;
    font-size: 46px;
    line-height: 100%;
    color: #000;
    text-align: center;
    max-width: 770px;
    margin: 0 auto;

    div {
      display: inline;
    }
  }

  &-subtitle {
    font-size: 25px;
    line-height: 32px;
    color: #000;
    text-align: center;
    max-width: 670px;
    text-align: center;
    margin: 20px auto 40px;
  }



  &-cover {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 30px;
    bottom: 30px;
  }

  &-buttonApply {
    align-items: center;
    background: #FF3C14;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-family: $font-semibold;
    font-size: 18px;
    justify-content: center;
    height: 55px;
    width: 177px;
    text-align: center;
    margin: auto;
    transition: all 0.25s ease;

    &:hover {
      background: #FF3C14;
    }
  }

  .radioContainer {
    padding-left: 30px;
  }

  .showForm {
    top: 0;
  }

  &-form {
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    top: 100%;
    position: relative;
    margin-top: 20px;

    h5 {
      font-family: $font-semibold;
      font-weight: normal;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      color: #fff;
      max-width: 510px;
    }

    .customForm {
      margin: 0 auto;
    }

    button {
      display: block;
      font-family: $font-semibold;

      font-size: 16px;
      height: 52px;
      color: #FF3C14;
      width: 100%;
      background: #fff;

      &:hover {
        background: #000;
      }
    }

    input {
      border-color: #fff;
      background: transparent;
      height: 42px;
      color: #fff;
    }

    div {
      max-width: 440px;
      margin: 0 auto;
    }

    label {
      font-size: 14px;
      color: #FFFFFF;
      margin-bottom: 10px;
      text-align: left;
    }

    a {
      color: #000;
      transition: all 0.25s ease;

      &:hover {
        color: #FF3C14;
      }
    }
  }

  .radioList {
    label {
      margin-bottom: 20px;
    }
  }

  &-thanks {
    background: #FF3C14;

    &__text {
      font-family: $font-semibold;
      color: #fff;

      h5 {
        font-size: 25px;
        line-height: 32px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        width: 100%;
        text-align: center;
      }
    }

    .customLand2-footer {
      text-align: center;
    }
  }

  &-formThanks {
    height: calc(100vh - 100px);
    max-width: 510px;

    h5 {
      font-weight: normal;
      font-size: 22px;
      line-height: 28px;
      line-height: 120%;
      color: #111;
    }

    a {
      color: $primary-color;
      transition: all 0.25s ease;

      &:hover {
        color: #000;
      }
    }
  }

  &-footer {
    bottom: 0;
    position: absolute;
    margin-top: 30px;
    padding-bottom: 40px;
    width: 100%;
    color: #fff !important;


    ul {
      display: flex;
      list-style: none;
      justify-content: center;
    }

    li {
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      margin-bottom: 0;
      margin-right: 30px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }

    a {
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      text-decoration: underline;
      transition: all .25s ease;

      &:hover {
        color: #000;
      }
    }
  }

  &-footerAlt {
    bottom: 0;
    position: absolute;
    margin-top: 30px;
    padding-bottom: 40px;
    width: 100%;

    ul {
      display: flex;
      list-style: none;
      justify-content: center;
    }

    li {
      font-size: 14px;
      line-height: 18px;
      color: #888;
      margin-bottom: 0;
      margin-right: 30px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }

    a {
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      text-decoration: underline;
      transition: all .25s ease;

      &:hover {
        color: #111;
      }
    }

  }

  // .radioContainer:hover input~.checkmark {
  //   border: 1px solid #fff;
  //   background: #fff;
  // }

  .radioContainer input:checked~.checkmark {
    background-color: transparent;
    border: 1px solid #fff;
  }

  .checkmark {
    background: transparent;
    border: 1px solid #fff;
  }

  .checkmark:after {
    content: " ";
    background: url("/assets/checkWhite.svg") no-repeat;
    background-position: center;
    position: absolute;
    display: none;
  }
}

#myScrollToElement {
  position: relative;
}

.eyes {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: 100px;
  top: 215px;

  .eye {
    border-radius: 50%;
    height: 25px;
    width: 25px;

    .pupil {
      position: absolute;
      background-color: #111;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      left: 7px;
      top: 7px;

    }
  }
}

.eyesThanks {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: 100px;
  top: 33px;

  .eye {
    border-radius: 50%;
    height: 25px;
    width: 25px;

    .pupil {
      position: absolute;
      background-color: #111;
      border-radius: 50%;
      width: 5px;
      height: 5px;
      left: 7px;
      top: 7px;

    }
  }
}

.overlayCustomLand {
  height: 100%;
  width: 0;
  max-width: 1200px !important;
  position: fixed;
  z-index: 999;
  right: 0;
  top: 0;
  background-color: rgba(29, 38, 71, 0);
  overflow: hidden;
  transition: all 1s ease;
}

.overlayCustomLand-content {
  position: relative;
  text-align: left;
  padding: 80px;
  background: $primary-color;
  max-width: 1200px;
  height: 100%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.25s ease;

  &-buy {
    &:hover>.overlay-content {
      background-color: #95983a;
    }
  }

  &-box {
    margin: auto;
    max-width: 500px;
    transition: all 0.2s ease;
    transition-delay: 0.2s;
    opacity: 0;
  }

  ul {
    list-style: none;

    li {
      display: flex;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;

      svg {
        margin-right: 15px;
      }
    }
  }

  &__menuMin {
    margin-top: 15%;

    a {
      font-size: 24px !important;
    }

    .enter {
      color: #fff;
      opacity: 0.5;

      &:hover {
        color: #fff;
      }
    }
  }

  h2 {
    font-size: 22px;
    line-height: 26px;
    color: #ffffff;
    margin-bottom: 25px;
  }

  &-title {
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 40px !important;
  }

  p {
    margin-bottom: 50px;
  }

  a {
    color: #fff;
    font-family: $font-semibold;
    display: inline-block;
  }

  label {
    font-weight: normal;
    font-size: 13px;
    color: #9aa1b9;
    margin-bottom: 10px;
  }

  &__btnRow {
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;

    button {
      font-size: 14px;
      margin-right: 15px;
      height: 36px;
    }
  }

  &__btnRowAlt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  &__inputRow {
    width: 50%;
    max-width: 275px;

    button {
      font-size: 14px;
      margin-right: 15px;
      height: 36px;
    }
  }
}

.overlayCustomLand .closebtn {
  opacity: 1;
  position: absolute;
  top: 30px;
  right: 30px;
  transition: all 0.25s ease;
  z-index: 1;
  color: #fff;
}

.rotating {
  -webkit-animation: rotating 20s linear infinite;
  -moz-animation: rotating 20s linear infinite;
  -ms-animation: rotating 20s linear infinite;
  -o-animation: rotating 20s linear infinite;
  animation: rotating 20s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@media (max-width: 900px) {
  .customLand-guy {
    height: auto;
    width: 250px;
  }

  .eyes {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    right: 60px;
    top: 135px;

    .eye {
      border-radius: 50%;
      height: 15px;
      width: 15px;

      .pupil {
        position: absolute;
        background-color: #111;
        border-radius: 50%;
        width: 3px;
        height: 3px;
        left: 5px;
        top: 5px;

      }
    }
  }

  .customLand-bubbles {
    height: auto;
    width: 100px;
  }

  .customLand-bubbles2 {
    height: auto;
    width: 50px;
    margin-left: 100px;
    margin-bottom: -40px;
  }

  .customLand-title {
    color: #111;
    font-size: 58px;
    width: 85%;
  }
}

@media (max-width: 650px) {
  .customLand {
    margin: 30px;
    padding: 0;

    &-button {
      position: initial;
      width: 103px;
      margin: auto;
      margin-bottom: 60px;
      align-items: center;
      justify-content: center;
    }

    &-intro {
      height: auto;
      width: 315px;
      margin: auto;
    }

    &-guy {
      display: none;
    }

    &-bubbles {
      display: none;
    }

    &-bubbles2 {
      display: none;
    }

    &-guyMobile {
      display: block;
      margin: auto;
    }

    &-title {
      font-size: 34px;
      line-height: 110%;
      text-align: center;
      color: #111;
      width: 90%;
    }

    &-subtitle {
      font-size: 16px;
      line-height: 140%;
      text-align: center;

      color: #878787;
    }

    &-buttonApply {
      position: initial;
      margin: auto;
      margin-top: 30px;
    }

    &-cover {
      position: initial;
      margin-top: 30px;
    }

    &-form {
      height: auto;
      margin-top: 30px;
      margin: auto;
      max-width: 315px;
      margin-bottom: 30px;

      h5 {
        text-align: center;
        font-size: 20px;
        line-height: 150%;
        margin: 60px auto 50px;
        width: 315px;
      }

      button {
        font-family: 16px;
        margin-top: 20px;
      }
    }

    &-footer {
      display: block;
      padding-bottom: 30px;

      ul {
        justify-content: center;
        margin-bottom: 60px;
      }
    }
  }

  .customLand2-navItem-full {
    display: none;
  }

  .customLand2-navItem-responsive {
    display: block;
  }

  .customLand2 {
    &-title {
      font-size: 30px;
      margin: 0 20px;
    }

    &-subtitle {
      font-size: 16px;
    }

    &-nav {
      height: 80px;
      padding: 0 30px;
    }

    &-form {
      margin: 0 auto;

      form {
        width: 90%;
      }

      input {
        height: 38px;
        margin-bottom: 10px;
      }

      button {
        height: 45px;
      }
    }

    .customForm {
      margin: 20px;
    }

    &-form h5 {
      font-size: 18px;
      line-height: normal;
    }

    .redNav {
      background: #FF3C14;
    }
  }

  .overlayCustomLand {
    transition: all .5s ease;

    &-content {
      padding: 40px;
      width: 100%;
    }
  }

  .overlayCustomLand-content-box {
    width: 255px;
  }

  .customLand-formThanks {
    height: 150px;
  }

  .overlayCustomLand .closebtn {
    right: 50%;
    margin-right: -52px;
  }

  .eyes,
  .eyesThanks {
    display: none;
  }
}