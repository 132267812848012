.settings-headerModal {
  margin-top: 0 !important;
  padding: 0 !important;
}

.settings-header {
  margin-top: 82px;
  padding: 50px 0 0;

  &__title {
    font-family: $font-semibold;
    font-size: 20px;
    line-height: 120%;
    color: #000000;
  }

  &__breadcrumb {
    display: flex;
    flex-direction: column;

    .button {
      align-items: center;
      margin-left: auto;

      svg {
        margin-left: 10px;
      }
    }
  }

  &__breadcrumbAlt {
    display: flex;
    margin-bottom: 40px;

    .button {
      align-items: center;
      margin-left: auto;

      svg {
        margin-left: 10px;
      }
    }
  }

  &__breadcrumbCatalog {
    display: flex;
    margin-bottom: 20px;

    .button {
      align-items: center;
      margin-left: auto;

      svg {
        margin-left: 10px;
      }
    }
  }

  &__breadcrumbCatalogTitle {
    display: flex;
  }

  &__breadcrumbArrow {
    margin: 0 14px;
  }
}

.settings-headerAlt {
  padding-top: 132px;
}

.settings-headerAlt-catalogo {
  padding-top: 152px;
}

.resultsNumber {
  color: #111;
  font-size: 12px;
  margin-left: 10px;
}

.settingsDivider {
  margin: 20px auto 20px 0;
  width: 220px;
}

.ReactModal__Body--open {
  overflow: hidden !important;
}

.slick-dots {
  bottom: -40px !important;
  z-index: 9999;
}

.slick-dots li button:before {
  font-size: 9px !important;
}

.settings-from__proLabel {
  font-family: $font-semibold;
  line-height: 37px;
}

.containerSpinner {
  position: relative;
  box-sizing: border-box;
  padding: 30px;
  height: 140px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .circle {
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 5px solid #eee;
    border-top-color: #111;
    animation: spin 1s infinite linear;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.settings-formReady {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.settings-form {
  label {
    display: flex;
    svg {
      margin-left: 5px;
    }
  }
  &__welcomeSlider {}

  .modal-text {
    text-align: center;
  }
  &__input {
    padding-right: 10px !important;
  }
  &__breadcrumb-title {
    margin: 0 10px;
  }

  &__container {
    height: 77vh;
    .switch {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 15px;
      margin: 0px 10px;
    }
    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 9px;
      width: 9px;
      left: 2px;
      bottom: 3px;
      background-color: white;
      transition: .4s;
    }
    input:checked+.slider {
      background-color: $primary-color;
    }
    input:focus+.slider {
      box-shadow: 0 0 1px #2196F3;
    }
    input:checked+.slider:before {
      -webkit-transform: translateX(10px);
      -ms-transform: translateX(10px);
      transform: translateX(10px);
    }
    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }
    .slider.round:before {
      border-radius: 50%;
    }
  }
  &__containerPlans {
    max-height: 590px;
    padding-bottom: 50px;
    .buttonPro {
      height: 42px;
      margin-bottom: 30px;
      max-width: none;
      width: 100%;
    }
  }

  &__modal {
    overflow: scroll;
    // max-height: 570px;  
  }

  &__cardRow {
    display: flex;

    img {
      margin-right: 10px;
    }
  }

  &__proChecklistList {
    line-height: 150%;

    li {
      list-style: none;
      display: flex;
    }

    li::before {
      content: '';
      display: inline-block;
      margin-right: 10px;
      height: 20px;
      width: 20px;
      background-image: url("/assets/checkList.svg");
    }

  }

  &__proChecklist {
    .buttonPro {
      max-width: none !important;
    }
  }

  &__menu {
    list-style: none;
    margin-bottom: 50px;

    li {
      margin-bottom: 0;
    }

    a {
      border-radius: 6px;
      color: #111;
      display: block;
      padding: 10px;
      width: 220px;
    }

    .active {
      background: rgba(238, 238, 238, 0.5);
      color: $primary-color;
      font-family: $font-semibold;

      &::after {
        content: '';
        background: url('/assets/activeArrow.svg');
        height: 20px;
        width: 20px;
        left: 190px;
        position: absolute;
      }
    }
  }

  &__productPic {
    // background: rgba(238, 238, 238, 0.25);    
    // border: 1px dashed #dddddd;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    // height: 100%;
    min-height: 462px;
    max-width: 400px;
    margin-left: auto;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;

    .itemTagAlt {
      margin-left: 0;
    }

    span {
      font-family: $font-semibold;
    }
  }

  &__productPicRow {
    display: flex;
    align-items: flex-start;
    text-align: center;
    height: 85px;
    width: 400px;
    margin-left: auto;
    position: relative;

    div:nth-child(1) {
      margin-right: 20px;
    }

    div:nth-child(2) {
      margin-right: 20px;
    }

    div:nth-child(3) {
      margin-right: 20px;
    }

    .settings-form__productPic {
      width: 85px;
      height: 85px;
      min-height: unset;
      display: flex;
      margin-bottom: 0;
      margin-left: 0;
    }

    .settings-form__productPicEdit {
      padding: 0;
      top: 5px;
      left: 5px;
      background: transparent !important;

      rect {
        transition: .3 ease;
      }

      &:hover {
        rect {
          fill-opacity: 1;
        }
      }
    }

    .settings-form__productPicDelete {
      top: 5px;
      right: 5px;
      margin-right: 0 !important;

      circle {
        transition: .3 ease;
      }

      &:hover {
        circle {
          fill-opacity: 1;
        }
      }
    }

    .settings-form__productPicPreview {
      min-height: auto;
      height: auto;
      width: 100%;
    }

    .fileDrop-container {
      min-height: auto;
      height: 84px;
      margin-left: 0;
      width: 84px;

      .file-drop-target {
        padding: 10px;
      }

      p {
        font-size: 12px;
      }

      .settings-form__productPic {
        margin-left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__productPicError {
    margin-left: auto;
    margin-top: 20px;
    text-align: center;
    width: 400px;
  }

  &__productPicAdd {
    background: rgba(238, 238, 238, 0.25);
    border: 1px dashed #dddddd;
    box-shadow: inset 0 0 0px 10px white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 85px;
    height: 85px;
  }

  &__productPicPreview {
    background: rgba(232, 232, 238, 0.1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    // height: 100%;
    min-height: 400px;
    max-width: 400px;
    margin: auto;
    position: relative;
    width: 100%;
  }

  &__productPicDelete {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;

    rect {
      transition: .3s ease;
    }

    &:hover {
      rect {
        fill-opacity: 1;
      }
    }
  }

  &__productPicEdit {
    background: rgba(17, 17, 17, 0.6);
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    padding: 7px 10px;
    position: absolute;
    top: 20px;
    left: 20px;
    transition: all .3s ease;

    &:hover {
      background: rgba(17, 17, 17, 1);

    }
  }

  &__productPicNote {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #999999;
    margin-top: 20px;
  }

  &__row {
    display: flex;
    margin-bottom: 20px;

    .fieldError {
      margin-bottom: 0;
      margin-top: 10px;
    }

    .fieldErrorUrl {
      margin-bottom: 0;
      margin-top: 9px;
      position: absolute;
      right: 10px;
      top: 0;
      display: block !important;

      &-hide {
        display: none;
      }
    }

    .fieldSuccess {
      margin-bottom: 0;
      margin-top: 9px;
      position: absolute;
      right: 20px;
      top: 0;
      display: block !important;

      &-hide {
        display: none;
      }
    }
  }

  &__rowCrop {
    flex-direction: column;
  }

  &__rowDouble {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__rowLast {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    width: 65.3333333333%;
    margin-left: 30%;
    position: absolute;
    bottom: 0;
    right: 40px;

    &-modal {
      z-index: 999;
      background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0.85) 80%, rgba(255, 255, 255, 0) 100%);
      // background: linear-gradient(224.86deg, rgba(255, 255, 255, 0) 50%, #FFFFFF 75.06%);    
    }

    &-modalWelcome {
      background: #fff;
      bottom: 10%;
      justify-content: center;
      margin-left: 0;
      width: 100%;
      right: 0;

      .button {
        align-items: center;
        position: relative;
        padding-right: 40px;

        &:hover {
          svg {
            right: 5px;
          }
        }

        svg {
          transition: all .3s ease;
          position: absolute;
          right: 10px;
          margin-left: 5px;
        }
      }
    }
  }

  &__sidebar {
    position: fixed;
  }

  &__container {
    margin-left: 34.7%;
  }

  &__column {
    position: relative;

    textarea {
      height: 124px;
    }
  }

  &__columnLabel {
    width: 35%;

    label {
      font-family: $font-regular;
      font-size: 14px;
      padding-top: 12px;
    }
  }

  &__columnLabelDelete {
    display: flex;
    width: 35%;

    label {
      font-family: $font-regular;
      font-size: 14px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &__columnLabelAlt {
    display: flex;
    align-items: center;
    width: 35%;

    label {
      font-family: $font-regular;
      color: #888;
      font-size: 14px;
    }
  }

  &__columnField {
    width: 65%;
    padding-right: 10px;
  }

  &__columnSwitch {
    align-items: center;
    display: flex;
    width: 65%;
    justify-content: flex-end;

    p {
      width: 90%;
    }
  }

  &__columnDescripcion {
    span {
      top: 95px !important;
      right: 10px !important;
      left: initial !important;
      color: #66666a;
      font-weight: normal !important;
    }
  }

  .columnRow {
    display: flex;
    align-items: center;
  }

  &__input {
    width: 100%;
  }

  &__thumb {
    height: 60px;
    width: 60px;
    border-radius: 60px;
    margin-right: 20px;
    clip-path: circle(60px at center);
  }

  &__thumbNav {
    height: 30px;
    width: 30px;
    clip-path: circle(30px at center);
    border-radius: 50%;
  }

  &__thumbNavPro::after {
    content: '';
    background: url('../images/pro_badge.svg');
    background-position: center center;
    background-size: cover;
    height: 16px;
    width: 16px;
    position: absolute;
    margin-left: -12px;
    margin-top: 18px;
  }

  &__thumbNavProResponsive::after {
    content: '';
    background: url('../images/pro_badge.svg');
    background-position: center center;
    background-size: cover;
    height: 16px;
    width: 16px;
    position: absolute;
    margin-left: -12px;
    margin-top: 25px;
  }

  .okkiurlInput {
    padding-left: 82px;

    &-placeholder {
      background: transparent;
      border-radius: 6px 0 0 6px;
      border-right: 1px solid #e8e8ee;
      color: #A1A4AE;
      position: absolute;
      padding: 6px 10px;
      line-height: 26px;
    }
  }

  .facebookurlInput {
    padding-left: 108px;

    &-placeholder {
      color: #999;
      position: absolute;
      padding: 6px 10px;
      line-height: 26px;
    }
  }

  .instagramurlInput {
    padding-left: 111px;

    &-placeholder {
      color: #999;
      position: absolute;
      padding: 6px 10px;
      line-height: 26px;
    }
  }

  .phoneInput {
    padding-left: 85px;

    &-placeholder {
      color: #999;
      display: flex;
      position: absolute;
      padding: 6px 10px;
      line-height: 26px;
      border-right: 1px solid #e8e8ee;

      img {
        margin-right: 10px;
      }
    }
  }

  .mapsInput {
    padding-left: 100px;

    &-placeholder {
      color: #999;
      position: absolute;
      padding: 6px 10px;
      line-height: 26px;
    }
  }

  .btnDelete {
    background: #fff;
    border: 1px solid #F63C44;
    color: #F63C44;
    display: inline-block;
    margin-bottom: 20px;

    &:hover {
      background: #ff000b;
      color: #fff;
    }
  }

  &__charCounter {
    span {
      font-family: $font-regular !important;
      font-weight: normal !important;
      right: 0 !important;
      top: -26px !important;
      color: #888 !important;
    }
  }
}

.file-drop-target {
  border: 1px dashed #dddddd;
  padding: 20px;
  transition: .3s ease;

  &:hover {
    border-color: #888;
    cursor: pointer;
  }
}

.file-drop-dragging-over-target,
.file-drop-dragging-over-frame {
  box-shadow: none !important;
  border: 1px dashed #000 !important;
}

.slick-slider {
  height: 400px;
}

.proButton {
  position: absolute;
  width: 125px;
  text-align: center;
  z-index: 9;
  margin-top: 100px;
  margin-left: 125px;
}

.newProduct {
  &-rowLast-container {
    .buttonPublish {
      align-items: center;
      position: relative;
      padding-right: 40px;
      width: 120px;


      &:hover {
        svg {
          right: 5px;
        }
      }

      svg {
        transition: all .3s ease;
        position: absolute;
        right: 10px;
        margin-left: 5px;
      }
    }
  }

  &__pro-title {
    font-family: $font-semibold;
    font-size: 16px;
  }

  &__charCounter {
    span {
      font-family: $font-regular !important;
      font-weight: normal !important;
      left: 320px !important;
      top: -26px !important;
      color: #888 !important;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 360px;

    hr {
      margin-bottom: 20px;
      margin-top: 20px;
      width: 100%;
    }

    textarea {
      padding-right: 10px !important;
      height: 124px;
    }
  }

  &__row2 {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    max-width: 360px;
  }

  &__divider {
    margin: 20px auto 20px 0;
    max-width: 360px;
  }

  &__proFeature {
    opacity: 1;

    .buttonPro {
      height: 42px;
      margin-bottom: 30px;
      max-width: none;
      max-width: 360px;
      width: 100%;
    }
  }

  &__column {
    width: 100%;

    .fieldError {
      margin-top: 5px;
      margin-bottom: 0;
    }

    label {
      font-size: 14px;
      display: flex;
      align-items: center;

      svg {
        margin-left: 5px;
      }
    }
  }

  &__columnDescripcion {
    span {
      top: -25px !important;
      right: 0 !important;
      left: initial !important;
      color: #66666a;
      font-weight: normal !important;
    }
  }

  &__column2 {
    width: 75%;

    &__column3 {
      width: 25%;

      label {
        margin-bottom: 0 !important;
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__rowDouble {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    max-width: 360px;
  }

  &__proTitle {
    font-family: $font-semibold;
    font-size: 12px;
    line-height: 15px;
    color: #111;
    margin-bottom: 20px;
  }

  &-contentRow {
    padding-bottom: 100px;
  }
}

.deleteItem {
  &-actions {
    justify-content: space-around !important;
  }
}

.newProduct-header {
  align-items: center;
  background: #111;
  color: #fff;
  display: flex;
  font-family: $font-semibold;
  font-size: 16px;
  line-height: 20px;
  justify-content: center;
  height: 82px;
  margin-bottom: 80px;
}

.newProduct-rowLast {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 83px;
  background: #fff;
  border-top: 1px solid #E8E8EE;
  align-items: center;
  z-index: 9999;

  &-container {
    max-width: 1160px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .button {
    align-items: center;
    display: flex;
  }
}

.newProduct-divider {
  border-color: #e8e8ee;
  margin: 30px 0 !important;
}

.ReactCrop {
  height: 100%;

  div:first-child {
    height: 100%;
  }

  // div {
  //   height: 100%;
  // }

  // &__image {
  //   margin: auto;
  //   height: 100%;
  // }

}

.modalCrop {
  height: 100%;

  &-title {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    font-family: $font-semibold;
    color: #111111;
    margin-bottom: 20px;
  }

  .fileDrop-container {
    min-height: 400px;
    max-width: 400px;
    margin: auto;
    height: 100%;
  }

  .settings-form__productPic {
    margin: auto;
    width: 100%;
    max-width: 650px;
    height: 75%;
    min-height: auto;
    position: relative;
  }

  .buttonCut-container {
    justify-content: space-between;
    margin: 20px auto 0;
    width: 100%;
    max-width: 650px;
  }

  &-container {
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (min-width: 600px) {
  .NuevoProducto-cropContainer-1 {
    height: 85% !important;
  }
}

.fileDrop-container {
  min-height: 400px;
  max-width: 400px;
  margin-left: auto;
  height: 100%;
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
}

.dropdownItem {
  cursor: pointer;
  font-size: 14px;
  padding: 12px 14px;

  &:hover {
    border-radius: 6px;
    background: #F5F5F5;
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .button {
    align-items: center;
    display: flex;

    svg {
      margin-left: 10px;
    }
  }
}

// .slick-dots {
//   bottom: -45px !important;
// }

.slick-dots li {
  margin: 0 !important;
}

.modalCookies {
  background-color: transparent !important;
}

.modal-title {
  font-family: $font-semibold;
  font-size: 30px;
  line-height: 38px;
  color: #111;
  margin: 20px 0;
}

.modal-subtitle {
  font-family: $font-semibold;
  font-size: 18px;
  line-height: 38px;
  color: #111;
  margin-bottom: 20px;
}

.modal-label {
  font-weight: normal;
  font-size: 12px;
  color: #111111;
  margin-bottom: 14px;
}

.modal-text {
  padding: 80px;
  padding-top: 0;
}

.modal-txt {
  color: #000;
}

.modal-textCookies {
  align-items: center;
  display: flex;
  padding: 11px;
  justify-content: space-between;

  svg {
    cursor: pointer;
    margin-right: 11px;
  }

  a {
    color: $primary-color;
  }

  a:hover {
    color: $primary-color;
    opacity: .7;
  }
}

.modal-divider {
  margin-bottom: 32px;
}

.modal-cover {
  margin-bottom: 60px;
  text-align: center;
  width: 100%;

  img {
    margin: auto;
  }
}

.modal-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.modal-columnBig {
  width: 66%;
}

.modal-columnSmall {
  width: 33%;
}

.modal-columnMid {
  width: 33%;
}

.locationModal {

  .modal-content {
    max-width: 340px;
  }

  .modal-text {
    padding: 40px;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    text-align: center;

    color: #66666A;
  }

  .modal-cover {
    margin-bottom: 40px;
  }

  .inputSelect {
    width: 100%;
  }

  .button {
    width: 100%;
  }
}

.modalOverlay {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;

  .modal-content {
    width: 100%;
  }

  .modal-text {
    text-align: center;
  }

  .modal-cover img {
    margin-top: -80px;
  }
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;

  &-settingsReady {
    margin: auto !important;
  }
}

.modal-contentWelcome {
  background-color: #fefefe;
  margin: 0 auto;
  border-radius: 8px;
  width: 80%;
  max-width: 550px;

  .modal-text {
    padding: 0;
  }

  .modal-cover {
    margin-bottom: 30px;

    &-settingsReady {
      max-width: 250px;
    }

    img {
      width: 100%;
    }
  }
}

.modal-contentThanks {
  margin: 0 auto;
  padding-top: 150px;
  text-align: center;

  .modal-cover {
    margin-bottom: 40px;
  }
}

.modal-cookies {
  background-color: #fefefe;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 80%;
  max-width: 440px;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);

  &__button {
    padding: 5px 10px !important;
    height: 25px !important;
  }
}

.modal-content2 {
  background-color: #fefefe;
  margin: 10% auto;
  border-radius: 8px;
  width: 80%;
}

.modal-text2 {
  padding: 40px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  margin-top: 10px;
  margin-right: 15px;

  path {
    transition: all 0.25s ease;
  }
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;

  path {
    transition: all 0.25s ease;
    fill: #000;
  }
}

.navbar-iconWrapper-close {
  display: none;
}

.settings-form__menuSelect {
  display: none;
  width: 100%;
}

.settingsModal {
  background: #fff;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 900px;
  max-height: 700px;
  height: 90%;
  padding: 40px;
  padding-right: 30px;
  border: none;
  border-radius: 12px;
  position: absolute;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.onlyMobile {
  display: none !important;
}

.displayNone {
  display: none;
}

@media (max-width:768px) {
  .settings-form__columnSwitch {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .settings-form__columnLabelDelete {
    width: 100%;
    margin-bottom: 10px;
  }

  .settings-form__columnFieldDelete {
    .button {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .settings-form__productPicRow {
    margin-bottom: 40px;
  }

  .newProduct-header {
    margin-bottom: 20px;
  }

  .fileDrop-container {
    margin-bottom: 20px;
  }

  .newProduct-rowLast-container {
    margin: 20px;
  }

  .newProduct-contentRow {
    overflow-x: hidden;
  }

  .priceSmall {
    width: 95%;
  }

  .newProduct__rowDouble {}

  .settingsModal {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 900px;
    height: 100%;
    max-height: none;
    padding: 20px;
    border: none;
    border-radius: 0;
    padding-top: 0;
    overflow: auto;
  }

  .settings-header__breadcrumbCatalog {
    flex-direction: column;

    .button {
      width: 100%;
      justify-content: center;
      margin-top: 20px;
    }
  }

  .onlyMobile {
    display: block !important;
  }

  .onlyDesktop {
    display: none !important;
  }

  .settings-headerAlt {
    padding-top: 142px;
  }

  .settings-form__container {
    margin-left: 0;
    margin-top: 0;
    height: auto;
    padding-left: 10px;
  }

  .settings-form__menuSelect {
    display: block;
    margin-top: 20px;
    appearance: none;
  }

  .settings-form__menuList {
    display: none;
  }

  .settings-form__sidebar {
    position: relative;
    margin-top: 100px;
  }

  .settings-form__rowLast-modal {
    position: inherit;
    width: 100%;
    margin: 0;
    margin-bottom: 2.5rem;
    flex-direction: column-reverse;

    .button {
      margin-bottom: 10px;
    }
  }

  .settings-form__modal {
    max-height: none;
  }

  .modal-text {
    padding: 0;
  }

  .settings-form__row {
    flex-direction: column;
    margin-bottom: 5px;
  }

  .settings-form__columnLabel {
    width: 100%;
  }

  .settings-form__columnField {
    width: 100%;
  }

  .settingsDivider {
    margin-bottom: 20px;
    width: 100%;
  }

  .navbar-iconWrapper-close {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .settings-form__rowLastMobile {
    .button {
      justify-content: center;
      width: 100%;
    }
  }

  .settings-form__welcomeSlider {
    padding-top: 100px;

    .slick-dots {
      bottom: 60px !important;
    }


  }

  .settings-form__rowLast-modalWelcome {
    bottom: 0;
    padding: 20px;

    .button {
      justify-content: center;
      width: 100%;

      svg {
        position: relative;
        right: -10px;
        margin-left: 0;
      }
    }
  }
}