.planContainer {
  display: flex;
  height: 100vh;

  .radioContainer {
    margin-bottom: 20px;
  }

  &-cover {
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 50%;
  }

  &-formAlt {
    background: #000;

    h1,
    p {
      color: #fff !important;
    }

    .button {
      display: inline-flex;
      margin-top: 20px;
    }
  }

  &-form {
    width: 50%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__mobileCover {
      margin-bottom: 40px;
      width: 100%;
    }

    &__containerLarge {
      height: 100vh;
      overflow: auto;
      padding-bottom: 250px;
      padding-top: 100px;
    }

    &__container {
      margin: auto;
      max-width: 360px;
      width: 100%;
      z-index: 1;


      .phoneInput {
        padding-left: 40px;

        &-placeholder {
          color: #000;
          position: absolute;
          padding: 6px 10px;
          line-height: 26px;
        }
      }
    }

    &__thanks {
      text-align: left;

      h1 {
        font-family: $font-semibold;
        font-size: 20px;
        line-height: 25px;
        color: #111;
      }

      p {
        font-size: 14px;
        line-height: 20px;

        color: #66666a;
      }

      svg {
        margin-right: 10px;
      }

      &-button {
        display: flex;
        color: $primary-color;
        margin-top: 20px;
        font-weight: normal;
        font-size: 14px;
        font-family: $font-semibold;
        line-height: 18px;

        &:hover {
          path {
            stroke: #111;
          }
        }
      }
    }

    &__input {
      width: 100%;
    }

    &__title {
      font-family: $font-semibold;
      font-size: 1.5rem;
      line-height: 150%;
      margin-bottom: 20px;
    }

    &__subtitle {
      color: #555555;
      font-family: $font-regular;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 1.25rem;
    }

    &__subtitleAlt {
      font-family: $font-regular;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin-bottom: 1.25rem;
      color: #000;
    }

    &__labelWrap {
      font-weight: normal;
      margin-bottom: 0;
      cursor: pointer;
      padding: 1.25rem;
    }

    &__option {
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 1.25rem;
      display: flex;
      flex-direction: column;

      .planContainer-form__optionList {
        display: none;
      }
    }

    &__optionRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: $font-regular;
    }

    &__optionAction {
      align-items: center;
      display: flex;

      p {
        color: #555555;
        font-family: $font-regular;
      }

      input[type="radio"] {
        margin-bottom: 0;
        margin-left: 1.25rem;
      }
    }

    &__optionList {
      list-style: none;
      margin-top: 1.25rem;

      li {
        color: #555555;
        margin-bottom: 0;
      }

      div {
        white-space: pre-wrap;
      }
    }

    &__optionExtra {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 1.25rem 0;

      p {
        color: #111111;
        font-family: $font-regular;
      }

      div {
        align-items: center;
        display: flex;
        color: $primary-color;

        p {
          font-family: $font-regular;
        }
      }

      .inputSelect {
        margin-bottom: 0;
        margin-left: 1.25rem;
        width: 68px;
      }
    }

    &__optionTotal {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      padding: 20px 0;

      position: absolute;
      bottom: 0;

      width: 45%;

      z-index: 1;

      background: #fff;

      border-top: 1px solid #eee;
      margin: 0 40px;

      p {
        font-family: $font-regular;
      }

      span {
        color: $primary-color;
        font-weight: normal;
      }

      hr {
        display: none;
      }

      .total {
        color: $primary-color;
        display: flex;

        strong {
          margin-left: 0.5rem;
        }
      }

      .totalNumber {
        font-weight: normal;
      }

      .label {
        color: #111111;
        font-weight: normal;
      }
    }

    &__optionTotalShadow {
      box-shadow: 0 0 4px rgba(0, 0, 0, .15);
    }

    &__optionInfo {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 1.25rem;

      p {
        font-family: $font-regular;
      }

      a {
        line-height: 38px;
      }

      .label {
        color: #111111;
        font-weight: normal;
      }

      .total {
        color: #555;
      }

      .totalNumber {
        font-weight: normal;
      }
    }

    &__cardIcons {
      position: absolute;
      top: 36px;
      right: 14px;
    }
  }
}

.registerForm {
  margin-top: -10px;

  &-row {
    display: flex;
    justify-content: space-between;

    &:nth-last-child(1) {
      margin-top: 20px;
    }
  }

  &-inputRow,
  .inputSelect {
    margin-right: 10px;
  }
}

.registerInput {
  margin-bottom: 20px;
  width: 100%;


}

.loginContainer {
  align-items: center;
  background: #fff;
  display: flex;
  position: relative;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  &-nav {
    color: #fff;
    display: flex;
    justify-content: space-between;
    height: 82px;
    padding: 0 30px;
    top: 0;
    position: absolute;
    width: 100%;

    .navbar-iconMenu div {
      background: #111;
    }

    &__item {
      align-items: center;
      display: flex;
      width: 33%;

      svg {
        margin-right: 10px;
      }

      a {
        color: #111;
        font-family: $font-semibold;
        line-height: 18px;
      }

      &:nth-child(2) {
        justify-content: center;
      }

      &:nth-child(3) {
        justify-content: flex-end;
      }
    }

    &__itemBack {
      display: flex;
    }
  }

  &-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    &__link {
      color: #111;
      text-align: center;

      &:hover {
        color: #a8a8a8;
      }
    }

    .button {
      justify-content: center;
      max-width: 440px;
      width: 90%;
    }

    .button-alpha {
      display: none;
    }
  }

  &-card {
    background: #fff;
    border: 1px solid #EEEEEE;
    border-radius: 12px;
    margin: 40px 0;
    padding: 40px;
    max-width: 440px;
    width: 90%;

    &__title {
      font-family: $font-semibold;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 40px;
      text-align: center;
    }

    &__row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      .checkboxContainer {
        margin-bottom: 20px;
      }
      label {
        margin-bottom: 0;
      }

      .button {
        width: 100%;
        justify-content: center;
      }
    }

    &__checkbox {
      margin-right: 10px;
    }

    &__input {
      margin-bottom: 20px;

      &:nth-last-child(2) {
        margin-bottom: 40px;
      }

      input {
        width: 100%;
      }

      // .inputEmail {
      //   text-transform: lowercase !important;
      // }
    }

    &__inputLast {
      font-family: $font-semibold;
      margin-top: 40px;
      text-align: center;
      margin-bottom: 0;
    }
  }
}

.radioContainer {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: $font-regular;
  font-size: 14px;
  line-height: 18px;

  color: #111;

  svg {
    margin-left: 5px;
  }

  &-vender {
    justify-content: space-between;
    padding-left: 35px;
  }
}

.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  transition: all 0.25s ease;
}

.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: $font-regular;
  font-size: 14px;
  line-height: 18px;

  color: #111;

}

.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmarkSquare {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: all 0.25s ease;
}

.checkmarkSquare2 {
  top: 6px;
}

// .radioContainer:hover input~.checkmark {
//   background-color: $primary-color;
//   border: 1px solid transparent;
// }

.radioContainer input:checked~.checkmark {
  background-color: $primary-color;
  border: 1px solid transparent;
}

// .checkboxContainer:hover input~.checkmarkSquare {
//   background-color: $primary-color;
// }

.checkboxContainer input:checked~.checkmarkSquare {
  background-color: $primary-color;
  border-color: $primary-color;
}

.checkmark:after {
  content: " ";
  background: url("/assets/check.svg") no-repeat;
  background-position: center;
  position: absolute;
  display: none;
}

.radioContainer input:checked~.checkmark:after {
  display: inline-block;
}

.radioContainer .checkmark:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  // background: white;
}

.checkmarkSquare:after {
  content: " ";
  background: url("/assets/check.svg") no-repeat;
  background-position: center;
  position: absolute;
  display: none;
}

.checkboxContainer input:checked~.checkmarkSquare:after {
  display: inline-block;
}

.checkboxContainer .checkmarkSquare:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  // background: white;
}

.signupContainer {
  overflow-x: hidden;
}

.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 20px;

  input {
    width: 100%;
  }

  svg {
    position: absolute;
    top: 23%;
    right: 5%;
  }

  svg:hover {
    path {
      stroke: #111;
      transition: all 0.25s ease;
    }

    cursor: pointer;
  }
}

.vender3-top {
  .navbar-iconWrapper {
    display: none;
  }
}


@media screen and (min-height: 900px) {
  .planContainer-form__containerLarge {
    height: auto;
  }
}

@media (max-width: 768px) {
  .navResponsive-vender {
    position: absolute;
    width: 100%;
    height: 82px;
    background: #fff;
    z-index: 999;
  }

  .okkiLogoMobile-vender {
    z-index: 10;
  }

  .vender1-container {
    justify-content: flex-start;
    margin-top: 120px;
    text-align: center;

    .radioContainer-vender {
      margin-bottom: 20px;
    }
  }

  .vender2-container {
    justify-content: flex-start;

    .radioContainer-vender {
      margin-bottom: 20px;
    }

    .planContainer-form__title,
    .planContainer-form__subtitleAlt {
      text-align: center;
    }
  }

  .planContainer-form__thanks {
    text-align: center;
  }

  .button-vender3 {
    justify-content: center;
    text-align: center;
    margin: 0 20px;
    width: 90%;
    position: absolute;
    bottom: 20px;
  }

  .loginContainer-card__input .inputEmail {
    text-transform: lowercase !important;
  }

  .navbarHideShow2 {
    background: #fff;
  }

  .planContainer-form__container {
    // margin-top: -15%;  
  }

  .planContainer .planContainer-cover {
    display: none;
  }

  .planContainer-form {
    width: 100%;

    &__optionTotal {
      width: 100%;
    }
  }

  .loginContainer-nav__itemBack {
    p {
      display: none;
    }
  }

  .planContainer-form__optionTotal {
    hr {
      display: block;
      margin-bottom: 20px;
      width: 100%;
    }

    flex-direction: column;
    padding: 20px 0;
    width: 90%;
    margin: auto;

    .button {
      justify-content: center;
      width: 100%;
    }

    button {
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  .planContainer-form__optionTotalMobile {
    position: relative;
    padding: 0;
    margin-top: 40px;

    .button {
      margin-bottom: 20px;
    }
  }

  .loginContainer-card {
    margin-top: 0;
    padding: 30px;
  }

  .loginContainer-card__rowActions {
    flex-direction: column;

    button {
      justify-content: center;
      margin-top: 20px;
      width: 100%;
    }
  }

  .loginContainer-nav__item svg {
    margin-right: 0 !important;
  }

  .loginContainer-nav__item {
    .button-alpha {
      display: none;
    }
  }

  .loginContainer-form .button-alpha {
    display: flex;
  }

  .planContainer-form__containerLarge {
    height: auto;
    // padding-bottom: 50px;
  }

  .navbarHideShow2 {
    .navbar-home__section {

      rect,
      path {
        fill: #111;
      }
    }
  }
}